import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_DATABASE_API_URL
const CATEGORIES_URL = `${API_URL}/categories`

const setUserData = (data: any): Promise<any> => {

    const all = axios
      .post(`${API_URL}updateAccount/userUpdate`, data)
      //.then((response: AxiosResponse<Response<Categories>>) => response.data)
      .then((response: any) => response.data)
  
  return all;
}

const getUserInfo = ()  => {
  const all = axios
    .post(`${API_URL}/users/userInfo`)
    //.then((response: AxiosResponse<Response<Categories>>) => response.data)
    .then((response) => response.data)
  return all
}
const getCountryDB = ()  => {
  const all = axios
    .post(`${API_URL}/users/getCountry`)
    .then((response) => response.data)
  return all
}
const getPhoneCodesDB = ()  => {
  const all = axios
    .post(`${API_URL}/users/getPhoneCodes`)
    .then((response) => response.data)
  return all
}
const getCityDB = (id:any)  => {
  const all = axios
    .post(`${API_URL}/users/getCity/${id}`)
    .then((response) => response.data)
  return all
}
const getTownDB = (id:any)  => {
  const all = axios
    .post(`${API_URL}/users/getTown/${id}`)
    .then((response) => response.data)
  return all
}


export {
  setUserData,
  getUserInfo,
  getCountryDB,
  getCityDB,
  getTownDB,
  getPhoneCodesDB
}
