import React, { useEffect, useState } from 'react'
import { getPhoneCodesDB } from '../accounts/components/settings/cards/_requests';
import {  toAbsoluteUrl } from '../../../_metronic/helpers';
import Select, { StylesConfig } from 'react-select'
import { Dropdown } from 'react-bootstrap';
import { PatternFormat } from 'react-number-format';
import clsx from 'clsx';
import { phoneMasks } from './PhoneMasks';

// const { colors } = defaultTheme;

const selectStyles: StylesConfig<any, false> = {
  //@ts-ignore
  control: (provided) => ({
    ...provided,
    minWidth: 200,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};


const PhoneInputLocal = (props: any) => {
  const [phoneCodeList, setPhoneCodeList] = useState<any>();
  const getPhoneCodes = async () => {
    await getPhoneCodesDB().then((degerli) => {
      setPhoneCodeList(degerli)
    })
  }

  useEffect(() => {
    getPhoneCodes();
  }, [])

  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneFormat, setPhoneFormat] = useState('(###)###-####')
  const [phoneMask, setPhoneMask] = useState('_')
  const [value, setValue] = useState<any | null>(
    { id: 218, iso: 'TR', name: 'TURKEY', nicename: 'Turkey', iso3: 'TUR', numcode: '792', phonecode: 90, value: 90, label: "TR +90" }
  );

  function convertPhoneNumber(str: string) {
    var regex = /\d+/g // Sayıları eşleştirecek düzenli ifade
    var sayilar = str.match(regex) // Düzenli ifadeye uyan tüm sayıları al
    var sadeceSayilar = sayilar ? sayilar.join('') : '' // Diziyi birleştirerek tek bir dize haline getir
    return sadeceSayilar
  }

  useEffect(() => {
    setIsOpen(false)
  }, [value])
  useEffect(() => {
    if (value.value !== "" && phone !== "") {

      props.formik.setFieldValue("phone", value.value + convertPhoneNumber(phone))
    }
  }, [value, phone])

  const changePhoneCode = (newValue: any) => {
    if (newValue !== value) {
      setIsOpen(false)
    }
    if (newValue.iso in phoneMasks) {
      setPhoneMask('_')
      setPhoneFormat(String(phoneMasks[newValue.iso]))
    } else {
      setPhoneFormat('###################')
      setPhoneMask('')
    }
    setValue(newValue);
  }

  return (
    <>
      <div className='row' >
        <div className='col-2 pe-0'>
          <div className="mb-10">
            
            <Dropdown show={isOpen} style={{ width: "100%!important" }}  >
              <div className="d-grid gap-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic" size='lg' className={clsx(
                  '',
                  { 'border border-danger': props.formik.touched.phone && props.formik.errors.phone },
                  {
                    'border border-success': props.formik.touched.phone && !props.formik.errors.phone,
                  }
                )} onClick={() => setIsOpen((prev) => !prev)} >
                  +{value.value}
                </Dropdown.Toggle>
              </div>
              <Dropdown.Menu >
                <Select
                  autoFocus
                  // onFocus={() => setIsOpen((prev) => !prev)}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={(newValue) => {
                    changePhoneCode(newValue)
                  }}
                  options={phoneCodeList}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={value}
                  onBlur={() => setIsOpen((prev) => !prev)}
                  formatOptionLabel={country => (
                    <div className="country-option">
                      <img src={toAbsoluteUrl('/media/flags/' + country.flag)} width={20} height={20} alt="yoook" className='mb-1' />
                      <span className='ms-2 mt-5'>{country.label}</span>
                    </div>
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className={clsx(
          'ps-1 ',
          {
            'offset-md-2 offset-lg-2 offset-sm-2 col-8': props.forgotPassword === 1,
          },
          {
            'col-10 ps-11': props.forgotPassword === 2,
          },
          {
            'col-10 ps-2': !props.forgotPassword,
          }
          )}
        >
          <div className="mb-10">

            <PatternFormat className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': props.formik.touched.phone && props.formik.errors.phone,
              },
              {
                'is-valid': props.formik.touched.phone && !props.formik.errors.phone,
              }
            )} value={phone} onChange={(e: any) => (setPhone(e.target.value))} onBlur={props.onBlur} name={props.name} format={phoneFormat} allowEmptyFormatting mask={phoneMask} />

          </div>
        </div>
      </div>

    </>
  )
}

export { PhoneInputLocal }
