export const phoneMasks: { [key: string]: string } ={
  "AD": "###-###",
  "AE": "##-###-####",
  "AF": "##-###-####",
  "AL": "(###)###-###",
  "AM": "##-###-###",
  "AN": "####-####",
  "AO": "(###)###-###",
  "AR": "(###)###-####",
  "AT": "(###)###-####",
  "AU": "#-####-####",
  "AZ": "##-###-##-##",
  "BA": "##-#####",
  "BD": "##-###-###",
  "BE": "(###)###-###",
  "BF": "##-##-####",
  "BG": "(###)###-###",
  "BH": "####-####",
  "BI": "##-##-####",
  "BJ": "##-##-####",
  "BN": "###-####",
  "BO": "#-###-####",
  "BR": "(##)#####-####",
  "BT": "##-###-###",
  "BW": "##-###-###",
  "BY": "(##)###-##-##",
  "BZ": "###-####",
  "CA": "(###)###-####",
  "CD": "(###)###-###",
  "CF": "##-##-####",
  "CG": "##-###-####",
  "CH": "##-###-####",
  "CI": "##-###-###",
  "CL": "#-####-####",
  "CM": "####-####",
  "CN": "##-#####-#####",
  "CO": "(###)###-####",
  "CR": "####-####",
  "CU": "#-###-####",
  "CV": "(###)##-##",
  "CW": "###-####",
  "CY": "##-###-###",
  "CZ": "(###)###-###",
  "DE": "(####)###-####",
  "DJ": "##-##-##-##",
  "DK": "##-##-##-##",
  "DZ": "##-###-####",
  "EC": "##-###-####",
  "EE": "####-####",
  "EG": "(###)###-####",
  "ER": "#-###-###",
  "ES": "(###)###-###",
  "ET": "##-###-####",
  "FI": "(###)###-##-##",
  "FJ": "##-#####",
  "FM": "###-####",
  "FO": "###-###",
  "FR": "(###)###-###",
  "GA": "#-##-##-##",
  "GE": "(###)###-###",
  "GF": "#####-####",
  "GH": "(###)###-###",
  "GI": "###-#####",
  "GL": "##-##-##",
  "GM": "(###)##-##",
  "GN": "##-###-###",
  "GQ": "##-###-####",
  "GR": "(###)###-####",
  "GT": "#-###-####",
  "GW": "#-######",
  "GY": "###-####",
  "HK": "####-####",
  "HN": "####-####",
  "HR": "##-###-###",
  "HT": "##-##-####",
  "HU": "(###)###-###",
  "ID": "###-####-####",
  "IE": "(###)###-###",
  "IL": "##-###-####",
  "IN": "(####)###-###",
  "IO": "###-####",
  "IQ": "(###)###-####",
  "IR": "(###)###-####",
  "IS": "###-####",
  "IT": "(###)####-###",
  "JO": "#-####-####",
  "JP": "##-####-####",
  "KE": "###-######",
  "KG": "(###)###-###",
  "KH": "##-###-###",
  "KM": "##-#####",
  "KP": "#-###-####",
  "KR": "##-###-####",
  "KW": "####-####",
  "KZ": "(###)###-##-##",
  "LB": "##-###-###",
  "LI": "(###)###-####",
  "LK": "##-###-####",
  "LR": "##-###-###",
  "LS": "#-###-####",
  "LT": "(###)##-###",
  "LU": "(###)###-###",
  "LV": "##-###-###",
  "LY": "##-###-####",
  "MA": "##-####-###",
  "MC": "(###)###-###",
  "MD": "####-####",
  "ME": "##-###-###",
  "MG": "##-##-#####",
  "MH": "###-####",
  "MK": "##-###-###",
  "ML": "##-##-####",
  "MM": "##-###-###",
  "MN": "##-##-####",
  "MO": "####-####",
  "MQ": "(###)##-##-##",
  "MR": "##-##-####",
  "MT": "####-####",
  "MU": "###-####",
  "MV": "###-####",
  "MW": "#-####-####",
  "MX": "(###)###-####",
  "MY": "(###)###-###",
  "MZ": "##-###-###",
  "NA": "##-###-####",
  "NC": "##-####",
  "NE": "##-##-####",
  "NF": "###-###",
  "NG": "(###)###-####",
  "NI": "####-####",
  "NL": "##-###-####",
  "NO": "(###)##-###",
  "NP": "##-###-###",
  "NR": "###-####",
  "NZ": "(###)###-####",
  "OM": "##-###-###",
  "PA": "###-####",
  "PE": "(###)###-###",
  "PG": "(###)##-###",
  "PH": "(###)###-####",
  "PK": "(###)###-####",
  "PL": "(###)###-###",
  "PS": "##-###-####",
  "PT": "##-###-####",
  "PW": "###-####",
  "PY": "(###)###-###",
  "QA": "####-####",
  "RE": "#####-####",
  "RO": "##-###-####",
  "RS": "##-###-####",
  "RU": "(###)###-##-##",
  "RW": "(###)###-###",
  "SA": "#-####-####",
  "SB": "###-####",
  "SC": "#-###-###",
  "SD": "##-###-####",
  "SE": "##-###-####",
  "SG": "####-####",
  "SI": "##-###-###",
  "SK": "(###)###-###",
  "SL": "##-######",
  "SM": "####-######",
  "SN": "##-###-####",
  "SO": "##-###-###",
  "SR": "###-####",
  "SS": "##-###-####",
  "SV": "##-##-####",
  "SY": "##-####-###",
  "SZ": "##-##-####",
  "TD": "##-##-##-##",
  "TG": "##-###-###",
  "TH": "##-###-####",
  "TJ": "##-###-####",
  "TL": "###-####",
  "TM": "#-###-####",
  "TN": "##-###-###",
  "TR": "(###)###-####",
  "TW": "#-####-####",
  "TZ": "##-###-####",
  "UA": "(##)###-##-##",
  "UG": "(###)###-###",
  "UK": "##-####-####",
  "UY": "#-###-##-##",
  "UZ": "##-###-####",
  "VA": "#-###-#####",
  "VE": "(###)###-####",
  "VN": "(###)####-###",
  "YE": "###-###-###",
  "ZA": "##-###-####",
  "ZM": "##-###-####"
}
