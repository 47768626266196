import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../auth'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {getFooterItems} from '../../core/_requests'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
function Footer() {
  const {cookieBox, saveCookieBox, genericValues} = useAuth()
  const [langData, setLangData] = React.useState<any>(genericValues?.langJson)
  const [texts, setTexts] = useState<any>({})
  const [footerItem1, setFooterItem1] = useState<any>([])
  const [footerItem2, setFooterItem2] = useState<any>([])
  const [photos, setPhotos] = useState<any>([])
  const lang = useLang()

  const intl = useIntl()
  let name = useParams()
  const [path, setPath] = useState(name['*'])

  useEffect(() => {
    setPath(name['*'])
    getFooterItems().then((res) => {
      if (res) {
        let footerItems = res.titles
        let footerItem1 = footerItems?.filter((x: any) => x.parent_id == 1)
        let footerItem2 = footerItems?.filter((y: any) => y.parent_id == 2)
        setFooterItem1(footerItem1)
        setFooterItem2(footerItem2)
        setTexts(res.texts)
        setPhotos(res.icons)
      }
    })
  }, [name])
  useEffect(() => {
    setLangData(genericValues?.langJson)
  }, [genericValues])
  const sum = (cookieBox?.obje ?? []).reduce((accumulator, object) => {
    return accumulator + object.total
  }, 0)

  return (
    <>
      <div className='d-block  d-sm-block  navbar-expand-sm  d-md-none  mt-10'>
        <nav
          style={{
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#f4f4f4',
            borderTop: '1px solid #e2e2e2',
          }}
          className=' navbar-expand-sm navbar fixed-bottom navbar-light py-4  '
        >
          <div style={{height: '40px'}} className='container-fluid  '>
            <Link
              style={{width: '25%', color: '#60697b'}}
              className={clsx('navbar-brand btn  m-auto p-2 text-center ')}
              to='/'
            >
              <i
                style={{color: '#60697b'}}
                className='las la-home svg-icon  me-0 pe-0 svg-icon-white fs-3x mb-2 '
              ></i>
              <div>{intl.formatMessage({id: 'MAIN.GENERAL.HOME'})}</div>
            </Link>
            <Link
              style={{width: '25%', color: '#60697b'}}
              className='navbar-brand btn  m-auto p-2 text-center'
              to='/auth'
            >
              <i
                style={{color: '#60697b'}}
                className='las la-user svg-icon  me-0 pe-0 svg-icon-white fs-3x mb-2 '
              ></i>
              <div>
                {' '}
                {intl.formatMessage({id: 'MAIN.GENERAL.LOGIN'})} /{' '}
                {intl.formatMessage({id: 'MAIN.GENERAL.MEMBER'})}
              </div>
            </Link>
            <Link
              style={{width: '25%', color: '#60697b'}}
              className={clsx('navbar-brand btn mobilFooterBtn  m-auto p-2 fw-normal text-center ')}
              to='/basket'
            >
              <span
                style={{position: 'absolute', color: 'green'}}
                className={clsx('badge badge-circle bg-warning ms-5 mt-3 ')}
              >
                {sum}
              </span>

              <i
                style={{color: '#60697b'}}
                className={clsx('las la-shopping-basket svg-icon   me-0 pe-0 svg-icon-white fs-3x')}
              ></i>
              <div className=''> {intl.formatMessage({id: 'MAIN.GENERAL.MYBASKET'})}</div>
            </Link>

            <button
              style={{width: '25%', color: '#60697b'}}
              type='button'
              className='navbar-brand btn mobilFooterBtn m-auto p-2 fw-normal text-center '
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
            >
              <i
                style={{color: '#60697b'}}
                className='fa-regular fa-credit-card svg-icon  me-0 mb-1 pe-0 svg-icon-white fs-3x   '
              ></i>

              <div className=''> {intl.formatMessage({id: 'MAIN.MONEY.CURRENCY.DILPARA'})}</div>
            </button>

            {/* <a className="navbar-brand" href="#">Fixed bottom</a>
                    <a className="navbar-brand" href="#">Fixed bottom</a>
                    <a className="navbar-brand" href="#">Fixed bottom</a> */}
          </div>
        </nav>
      </div>
      <div className='	d-none d-md-block d-xl-block   d-xl-block d-xxl-block d-lg-none'>
        <div className='container' style={{marginTop: '70px'}}>
          <div className='row'>
            <div key={0} className='col-xl-3 text-center'>
              <img
                className=' mb-5 img-fluid'
                alt='Logo'
                src={`${process.env.REACT_APP_MEDIA_API_URL}headerImages/${photos[0]?.photo_url}`}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = `${process.env.REACT_APP_MEDIA_API_URL}static/header-logo.png`
                }}
                // src={toAbsoluteUrl('/media/logos/bereket-logo.png')}
              />
              {/* <img
                className=' mb-5 img-fluid'
                // src={toAbsoluteUrl('/media/logos/bereket-dernegi-logo-1.png')}
                src={`${process.env.REACT_APP_MEDIA_API_URL}headerImages/${photos[0]?.photo_url}`}
              /> */}
              <span className=' bold'>
                <b>{texts[0]?.left_description}</b>
              </span>
            </div>
            <div className='col-xl-3 text-center fs-5'>
              {footerItem1.map((item: any, i: number) => {
                return (
                  <p key={i} className='mb-2'>
                    <b>{item[0]?.name}</b>
                  </p>
                )
              })}
              <p className='mb-3'>
                <b>{footerItem1[0]?.name}</b>
              </p>
              {footerItem1?.map((item: any, i: any) => {
                if (i === 0) {
                  return null // 0. elemanı gösterme
                }

                return (
                  <p key={i} className='mb-2'>
                    <a href={`${item.link}`}>
                      {langData ? langData['DB.FOOTERSUB_MENU.VALUE_' + item.id] ?? item.name : ''}
                    </a>
                  </p>
                )
              })}
              {/* <p className='mb-2'>
                <a href='https://www.bereket.org.tr/hakkimizda'>Hakkımızda</a>
              </p>
              <p className='mb-2'>
                <a href='https://www.bereket.org.tr//degerlerimiz'>Değerlerimiz</a>
              </p>
              <p className='mb-2'>
                <a href='https://www.bereket.org.tr/hesap-numaralari'>Hesap Numaraları</a>
              </p>
              <p className='mb-2'>
                <a href='https://www.bereket.org.tr/iletisim'>İletişim</a>
              </p> */}
            </div>
            <div className='col-xl-3 text-center fs-5'>
              <p className='mb-3'>
                <b>{footerItem2[0]?.name}</b>
              </p>
              {footerItem2?.map((item: any, i: any) => {
                if (i === 0) {
                  return null // 0. elemanı gösterme
                }

                return (
                  <p key={i} className='mb-2'>
                    <a href={`${item.link}`}>
                      {langData ? langData['DB.FOOTERSUB_MENU.VALUE_' + item.id] ?? item.name : ''}
                    </a>
                  </p>
                )
              })}
              {/* <p className='mb-2'>
                <a href='https://www.bereket.org.tr//su-kuyusu'>Su Kuyusu</a>
              </p>
              <p className='mb-2'>
                <a href='https://www.bereket.org.tr/kurban-bagisi'>Kurban Bağışı</a>
              </p>
              <p className='mb-2'>
                <a href='https://www.bereket.org.tr/iftar-bagisi'>İftar Bağışı</a>
              </p>
              <p className='mb-2'>
                <a href='https://www.bereket.org.tr/hafiz-yetistiriyorum'>Hafız Yetiştiriyorum</a>
              </p> */}
            </div>
            <div className='col-xl-3 text-center'>
              <img
                className=' w-100 mb-5'
                alt='Logo'
                src={`${process.env.REACT_APP_MEDIA_API_URL}headerImages/${photos[1]?.photo_url}`}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = `${process.env.REACT_APP_MEDIA_API_URL}static/footer-call-logo.png`
                }}
                // src={toAbsoluteUrl('/media/logos/bereket-logo.png')}
              />
            </div>
          </div>
        </div>

        <div className='  d-flex flex-column flex-md-row flex-stack p-5 justify-content-center mt-5 bg-one'>
          <div className='text-white text-center order-2 order-md-1 mb-5 mt-5'>
            <p>
              {texts[1]?.down_description}
              {/* <b>Bereket Derneği</b> © 2023 Tüm hakkı saklıdır. */}
            </p>
            <a
              href='https://www.dernekpro.com.tr/'
              target='_blank'
              className='text-muted text-hover-primary fw-bold me-2 fs-6 mb-5'
            >
              <img className='w-50 h-50' src={toAbsoluteUrl('/media/logos/dernekpro.png')} />
            </a>
            {/* <span className='text-gray-400 fw-bold me-1'>tarafından oluşturuldu</span> */}
          </div>
        </div>
      </div>
      <a
        href={`${
          process.env.REACT_APP_CONTROL === 'Vefa'
            ? 'https://api.whatsapp.com/send?phone=905304961757'
            : 'https://api.whatsapp.com/send?phone=905360673384&text=Merhaba, bağış yapmak istiyorum.'
        } `}
        className='float-whatsapp '
        target='_blank'
        style={{position: 'fixed', bottom: '70px', right: '10px',height:'55px',width:'55px',borderRadius:'50%',backgroundColor:'#25d366'}}
        rel='noreferrer'
      >
        <i style={{fontSize:'40px'}}   className='fa-brands fa-whatsapp my-float-whatsapp pt-1'></i>
      </a>
    </>
  )
}
export default Footer
