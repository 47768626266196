import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import PhoneInput from 'react-phone-input-2'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PhoneInputLocal } from '../../components/PhoneInputLocal'
const initialValues = {
  email: '',
  phone: ''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('yanlış e-posta formatı')
    .min(3, 'en az 3 karakter')
    .max(50, 'Maximum 50 symbols')
})

export function ForgotPassword() {
  const [phoneNumber, setPhoneNumber] = useState()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasErrors_yok, setHasErrors_yok] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      
      setLoading(true)
      setHasErrors(undefined)
      setHasErrors_yok(undefined)
      setTimeout(() => {
        requestPassword(values.email, values.phone, String(process.env.REACT_APP_ANASAYFA))
          .then(({ data: result }: any) => {
            if (result.islem === "hatalıdır") {
              setHasErrors_yok(true)
              setLoading(false)
            } else {
              window.location.href = `${process.env.REACT_APP_ANASAYFA}auth/change-password/${result.id}`;
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='container mt-20'>
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Şifremi Unuttum </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-dark-400 fw-bold fs-4'>Eposta veya telefon numaranı girerek şifreni sıfırlayabilirsin.</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Görünüşe göre bir hata oluştu lütfen tekrar deneyiniz.
              </div>
            </div>
          )}
          {hasErrors_yok === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Girilen mail veya telefon sisteme kayıtlı değil.
              </div>
            </div>
          )}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group  EMAİL*/}
          <span className='align-items-center justify-content-center text-center h2 d-block'>Veya</span>
          {/* begin::Form group  PHONE NUMBER*/}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Telefon Numarası</label>
            <PhoneInputLocal {...formik.getFieldProps('phone')} formik={formik} forgotPassword={1}  />
            {/* <PhoneInput
              inputStyle={{ width: '100%' }}
              enableSearch
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true
              }}
              {...formik.getFieldProps('phone')}
              country={'tr'}
              containerClass={''}
              value={phoneNumber}
              onChange={(phone: any) => formik.values.phone = phone}

            /> */}
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group  PHONE NUMBER*/}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
              disabled={loading}
            >

              {!loading ? (
                <span className='indicator-label'>Sıfırla</span>
              ) : (
                <span className='indicator-label'>İşlem Yapılıyor</span>
              )}
            </button>
            <Link to='/auth'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                İptal
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </div>
      </form>

    </>
  )
}
