import React, {useEffect, useState, useRef} from 'react'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import './styles/cardMain.css'
import Card from './components/card/Card'
import {toAbsoluteUrl} from './../../../_metronic/helpers/AssetHelpers'
import clsx from 'clsx'
import axios from 'axios'
import {getCategories, getCards, getCurrencyList, checkBasketCards} from './core/_requests'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, Navigate, Route, Routes} from 'react-router-dom'
import ContentPage from './components/ContentPage'
import {BasketPage} from './components/basket/BasketPage'
import './styles/Mobile.css'
import {CookieBoxModel, GenericValuesModel, useAuth} from '../auth'
import './styles/button.scss'
import {exit} from 'process'
import {Languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import {LanguageHeader} from './components/LanguageHeader'
import {useIntl} from 'react-intl'
import {useLang, setLanguage} from '../../../_metronic/i18n/Metronici18n'
import {getLanguagesList} from './core/_requests'

const GuestPage = () => {
  const intl = useIntl()
  const {cookieBox, saveCookieBox, genericValues, saveGenericValues} = useAuth()
  const [langData, setLangData] = useState<any>(genericValues?.langJson)
  useEffect(() => {
    setLangData(genericValues?.langJson)
  }, [genericValues])

  const checkBasket = async () => {
    const checkedBasket = await checkBasketCards(cookieBox?.obje ?? [])
    saveCookieBox({
      ...{
        obje: checkedBasket,
      },
    } as CookieBoxModel)
  }

  useEffect(() => {
    if ((cookieBox?.obje ?? []).length > 0) {
      checkBasket()
    }
  }, [])
  const [category, setCategory] = useState<any>([])
  const [moneyExchange, setMoneyExchange] = useState<any>([])
  const [exchange, setExchange] = useState(1)

  const [card, setCard] = useState<any>([])
  const [categoryId, setCategoryId] = useState('')

  const kategoriler = async () => {
    const {data: kategoriler} = await getCategories()
    setCategory(kategoriler)
    let has_dinle = window.location.hash
    if (has_dinle === '') {
      getContent(kategoriler[0]['id'])
    } else {
      has_dinle = has_dinle.replace('#', '')
      getContent(has_dinle)
    }
  }

  const getCurrency = async () => {
    const {data: currencyList} = await getCurrencyList()
    setMoneyExchange(currencyList)
  }

  const overlayEl = useRef(document.createElement('div'))

  useEffect(() => {
    getCurrency()
    kategoriler()
    //saveCookieBox(undefined)
    const copy = genericValues
    if (copy?.studenCheck !== undefined) {
      saveCookieBox(undefined)
      delete copy['studenCheck']
    }

    saveGenericValues({
      ...copy,
    } as GenericValuesModel)
  }, [])

  const changeCurrency = (val: any) => {
    saveCookieBox({
      ...{
        obje: (cookieBox?.obje ?? []).map((obj: any) => {
          // 👇️ if id equals 2, update country property
          obj.basket_id =
            obj.card_id +
            '-' +
            Math.round(obj.quantity * ((genericValues?.forTL ?? 1) / val.tl) * 100) / 100
          obj.quantity =
            Math.round(obj.quantity * ((genericValues?.forTL ?? 1) / val.tl) * 100) / 100
          obj.target = Math.round(obj.target * ((genericValues?.forTL ?? 1) / val.tl) * 100) / 100
          return obj
        }),
      },
    } as CookieBoxModel)
    saveGenericValues({
      ...genericValues,
      currency: val.id,
      currSym: val.simge,
      currShort: val.kisaadi,
      forTL: val.tl,
    } as GenericValuesModel)
  }

  const getContent = async (id: any) => {
    window.location.hash = id
    const myTimeout = setTimeout(myGreeting, 300)
    function myGreeting() {
      window.scrollTo(0, 0)
    }
    setCategoryId(id)
    const deger = await getCards(id)
    setCard(deger)
  }

  const deleteBasketCard = async (key: any, basket_id: any) => {
    if ((cookieBox?.obje ?? [])[key].total <= 1) {
      saveCookieBox({
        ...{
          obje: (cookieBox?.obje ?? []).filter((employee: any) => {
            // 👇️ remove object that has id equal to 2
            return employee.basket_id !== basket_id
          }),
        },
      } as CookieBoxModel)
    } else {
      saveCookieBox({
        ...{
          obje: (cookieBox?.obje ?? []).map((obj: any) => {
            // 👇️ if id equals 2, update country property
            if (obj.basket_id === basket_id) {
              if (Number(obj.values[obj.values.length - 1].piece) > 1) {
                obj.values[obj.values.length - 1].piece = String(
                  Number(obj.values[obj.values.length - 1].piece) - 1
                )
              } else {
                obj.values.pop()
              }
              return {...obj, total: obj.total - 1, values: obj.values}
            }
            // 👇️ otherwise return object as is
            return obj
          }),
        },
      } as CookieBoxModel)
    }
  }
  const [languages, setLanguages] = useState<any>([])
  const getLanguages = async () => {
    const languageList = await getLanguagesList()
    setLanguages(languageList)
  }
  const lang = useLang()
  const currentLanguage = languages.find((x: any) => x.lang === lang)
  useEffect(() => {
    getLanguages()
  }, [])
  function currencyFormat(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  let totalAmount = 0
  const [bgImage, setBgImage] = useState(toAbsoluteUrl('/media/svg/shapes/bg.svg'))

  return !langData ? (
    <div className='container'>
      <div className='row'>...</div>
    </div>
  ) : (
    <div>
      <div className='container'>
        <div className='row'>
          <div ref={overlayEl} className=' col-sm-12 col-md-9  '>
            <ul
              style={{backgroundColor: '#fff', borderRadius: ' 0px 0px 15px 15px'}}
              className='container justify-content-between navs nav-tabs nav-line-tabs mb-5 fs-6 mobile '
            >
              {category.map((val: any, index: any) => {
                return (
                  <li
                    data-v-29dcb1c6='true'
                    key={'category-' + index}
                    style={{cursor: 'pointer'}}
                    className='nav-item mt-5 mb-2  melement stt_test'
                  >
                    <div className='justify-content-between '>
                      <a
                        onClick={() => getContent(val.id)}
                        className={clsx('nav-link text-center mobilicon', {
                          active: val.id === categoryId,
                        })}
                        //className="nav-link text-center"${index + 1}
                        data-bs-toggle='tab'
                        href='#tab_1'
                      >
                        <img
                          className='me-1'
                          loading='lazy'
                          style={{width: '20px', height: '20px'}}
                          src={`${process.env.REACT_APP_MEDIA_API_URL}${val.icon}`}
                        />
                        <span style={{color: '#919594'}} className=' me-3 '>
                          <strong> {langData['DB.DONATE_CATEGORIES.VALUE_' + val.id]}</strong>
                        </span>
                      </a>
                    </div>
                  </li>
                )
              })}
            </ul>

            <div className='tab-content  '>
              <div
                className='tab-pane fade active  show align-items-center'
                id='tab_1'
                role='tabpanel'
              >
                <div className='row'>
                  <Card content={card} url={`content/${categoryId}`} />
                </div>
              </div>
              {/* <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <div className="row">
                  <Card content={card} />
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                <div className="row">
                  <Card content={card} />
                </div>
              </div> */}
            </div>
          </div>
          <div className='col-3 '>
            <div className='row mt-5 mb-15'>
              <button
                type='button'
                className='btn float-end d-none  d-sm-none d-md-block  bg-one text-white'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_2'
              >
                {intl.formatMessage({id: 'MAIN.MONEY.CURRENCY.DIL'})} {currentLanguage?.name} /{' '}
                {intl.formatMessage({id: 'MAIN.MONEY.CURRENCY.PARA'})} {genericValues?.currShort}{' '}
                {genericValues?.currSym}
              </button>
              <div className='modal fade mt-20' tabIndex={1} id='kt_modal_2'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h3 className='modal-title'>
                        {intl.formatMessage({id: 'MAIN.MONEY.CURRENCY.TITLE'})}{' '}
                      </h3>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2 bg-one'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body'>
                      <div className='row'>
                        <div className='col-6'>
                          {/* <span>Dil Seçiniz</span> */}
                          <div className='       '>
                            <LanguageHeader />
                          </div>
                        </div>
                        <div className='col-6'>
                          <span>{intl.formatMessage({id: 'MAIN.MONEY.CURRENCY.SELECT'})} </span>
                          <br />
                          {moneyExchange.map((val: any, i: any) => {
                            return (
                              <button
                                name='exchangebtn'
                                key={i}
                                type='button'
                                value={val.id}
                                className={clsx('btn btn-primary  border-0 float-end mt-3 col-12', {
                                  active: genericValues?.currency === val.id ? ' active ' : '',
                                })}
                                onClick={() => changeCurrency(val)}
                              >
                                {intl.formatMessage({id: 'MAIN.MONEY.CURRENCY.' + val.kisaadi})}{' '}
                                {val.simge}
                              </button>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Kapat
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{position: 'sticky', top: '80px'}}
              className='align-items-center d-none  d-sm-none d-md-block'
            >
              <div
                style={{borderRadius: '  0px 15px 0px 15px'}}
                className='card w-100 text-center '
              >
                <div
                  style={{borderRadius: ' 0px 15px 0px 0px'}}
                  className='card-header  align-items-center  justify-content-center bg-one'
                >
                  <h5 className=' d-inline-block   text-white '>
                    {intl.formatMessage({id: 'MAIN.BASKET.TITLE'})}
                  </h5>
                </div>
                <div className='card-body p-3'>
                  <div className='text'>
                    {(cookieBox?.obje ?? []).length === 0 ? (
                      <>
                        <h6> {intl.formatMessage({id: 'MAIN.BASKET'})}</h6>
                        <p>{intl.formatMessage({id: 'MAIN.BASKET.SUBTITLE'})} </p>
                      </>
                    ) : (
                      <>
                        {(cookieBox?.obje ?? []).map((val: any, index: any) => {
                          totalAmount = totalAmount + val.quantity * val.total
                          return (
                            <div className='row text-start p-3 ' key={index}>
                              <div className='col-9'>
                                <h2 className=''>
                                  {langData['DB.DONATE_LIST.VALUE_' + val.card_id]}
                                </h2>
                                {Number(val.pay_type === 6) ? (
                                  <p>
                                    <small>
                                      ({intl.formatMessage({id: 'MAIN.CARD.SPONSOR.TIME'})}) :{' '}
                                      {val.monthly} {intl.formatMessage({id: 'MAIN.CARD.MONTH'})})
                                    </small>
                                  </p>
                                ) : (
                                  <></>
                                )}
                                <h5>
                                  {currencyFormat(
                                    Math.round(val.quantity * val.total * 100) / 100
                                  ) + (genericValues?.currSym ?? '₺')}{' '}
                                </h5>
                                <p>
                                  <small>
                                    {currencyFormat(Math.round(val.quantity * 100) / 100) +
                                      (genericValues?.currSym ?? '₺')}{' '}
                                    x {val.total} {intl.formatMessage({id: 'MAIN.CARD.PIECE'})}
                                  </small>{' '}
                                </p>
                              </div>
                              <div className='col-3  pt-2'>
                                <a
                                  href='#'
                                  onClick={() => deleteBasketCard(index, val.basket_id)}
                                  className='btn btn-link btn-color-danger btn-active-color-primary '
                                >
                                  <i className='bi bi-dash-circle fs-3x me-2'></i>
                                </a>
                              </div>
                              <hr />
                            </div>
                          )
                        })}
                        <h3>
                          {intl.formatMessage({id: 'MAIN.BASKET.TOTAL'})}:{' '}
                          {currencyFormat(Math.round(totalAmount * 100) / 100) +
                            (genericValues?.currSym ?? '₺')}
                        </h3>
                        <br />
                        <div className='d-grid py-3 gap-2 mt-auto'>
                          <Link className='btn d-inline-block' to={'basket'}>
                            {' '}
                            <button
                              style={{borderRadius: ' 0px 15px 0px 15px', color: '#fff'}}
                              className=' btn-new btn-hov  d-inline-block sticky-bottom  bg-one'
                            >
                              <b>{intl.formatMessage({id: 'MAIN.BASKET.ICON'})}</b>
                            </button>
                          </Link>
                        </div>

                        {/* <input className='btn btn-success'>Spetim</input> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-block  d-sm-block d-md-none    '>
        <div className='container' style={{marginTop: '70px'}}>
          <div className='row'>
            <div className='col-xl-12 text-center'>
              <img
                className='w-100 mb-5'
                src={`${process.env.REACT_APP_MEDIA_API_URL}static/header-logo.png`}
              />
              <span className=' bold mt-5'>
                <b>
                  {process.env.REACT_APP_CONTROL} Derneği, Yurtiçi ve Yurtdışında insani ve islami
                  yardım faaliyetlerinde bulunan bir hayır kurumudur.
                </b>
              </span>
            </div>
            <div style={{marginTop: '100px'}} className='col-xl-12 text-center'>
              <img
                className='w-100 mb-5'
                src={`${process.env.REACT_APP_MEDIA_API_URL}static/footer-call-logo.png`}
                // src={toAbsoluteUrl('/media/logos/bereket-cagri-merkezi-1-3.png')}
              />
            </div>
          </div>
        </div>
        <div
          style={{backgroundColor: '#0e7154'}}
          className='  d-flex flex-column flex-md-row flex-stack mt-15 mb-15 justify-content-center p-5'
        >
          <div className='text-white text-center order-2 order-md-1 mt-5'>
            <p>
              <b>{process.env.REACT_APP_CONTROL} Derneği</b> © 2023 Tüm hakkı saklıdır.
            </p>

            <a
              href='https://www.dernekpro.com.tr/'
              target='_blank'
              className='text-muted text-hover-primary fw-bold me-2 fs-6'
            >
              <img className='w-50 h-50 mb-20' src={toAbsoluteUrl('/media/logos/dernekpro.png')} />
            </a>
            {/* <span className='text-gray-400 fw-bold me-1'>tarafından oluşturuldu</span> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const MainPage = () => (
  <div className='App'>
    <Header />
    <Routes>
      <Route>
        {/* <Route path='content/:categoryId/:contentId' element={<ContentPage />} /> */}
        <Route path='basket' element={<BasketPage />} />
        <Route index element={<GuestPage />} />
      </Route>
    </Routes>
    <Footer />
  </div>
)
export default MainPage
