import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useMutation} from 'react-query'
import {CookieBoxModel, useAuth} from '../../../../auth'
import 'react-phone-input-2/lib/bootstrap.css'
import PhoneInput from 'react-phone-input-2'
import tr from 'react-phone-input-2/lang/tr.json'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import './input.css'
import {PhoneInputLocal2} from '../../../../components/PhoneInputLocal2'
import {Button, Col, Row} from 'react-bootstrap'
function BasketCard(props: any) {
  function currencyFormat(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  const intl = useIntl()
  const ref = useRef(null)
  const {cookieBox, saveCookieBox, currentUser, genericValues, saveGenericValues} = useAuth()
  const [langData, setLangData] = useState<any>(genericValues?.langJson)
  useEffect(() => {
    setLangData(genericValues?.langJson)
  }, [genericValues])
  const initialValues = {
    name: String(props.items.values[props.order].name),
    lastname: '',
    email: '',
    phone: '',
    password: '',
    changepassword: '',
    acceptTerms: false,
  }
  const registrationSchema = Yup.object().shape({
    name: Yup.string().required(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {},
  })
  const checkBasketCard = async (itemProps: any, stat: any = 0) => {
    if (
      (!currentUser || Number(currentUser?.authority) === 1) &&
      itemProps.items.info_type === 1 &&
      stat === 1
    ) {
      Swal.fire({
        title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_5'}),
        text: intl.formatMessage({id: 'MAIN.CARD.WARNING_5'}),
        icon: 'warning',
        timer: 2500,
      })
      return false
    }
    let item = cookieBox?.obje ?? []
    if (item) {
      saveCookieBox({
        ...{
          obje: (cookieBox?.obje ?? []).map((obj: any) => {
            if (obj.basket_id === itemProps.items.basket_id) {
              if (stat === 0) {
                if (obj.values[itemProps.order].error) {
                  if (
                    obj.values[itemProps.order].other === true &&
                    obj.values[itemProps.order].autopay === false
                  ) {
                    obj.values[itemProps.order].error = false
                  }
                }
                if (itemProps.items.pay_type === 7) {
                  if (obj.values[itemProps.order].error) {
                    if (obj.values[itemProps.order].other === false) {
                      obj.values[itemProps.order].error = false
                    }
                  }
                }
                obj.values[itemProps.order].other = !obj.values[itemProps.order].other
              } else if (stat === 1) {
                if (obj.values[itemProps.order].error) {
                  if (
                    obj.values[itemProps.order].other === false &&
                    obj.values[itemProps.order].autopay === true
                  ) {
                    obj.values[itemProps.order].error = false
                  }
                }
                obj.values[itemProps.order].autopay = !obj.values[itemProps.order].autopay
              } else if (stat === 2) {
                if (obj.values[itemProps.order].error) {
                  if (
                    obj.values[itemProps.order].other === true &&
                    obj.values[itemProps.order].autopay === false
                  ) {
                    obj.values[itemProps.order].error = false
                  }
                }
                if (itemProps.items.pay_type === 7) {
                  if (obj.values[itemProps.order].error) {
                    if (obj.values[itemProps.order].other === false) {
                      obj.values[itemProps.order].error = false
                    }
                  }
                }
                for (let i = 0; i < obj.values.length; i++) {
                  obj.values[i].other = !obj.values[i].other
                }
              }
              return {...obj, values: obj.values}
            }

            return obj
          }),
        },
      } as CookieBoxModel)
    }
  }

  const checkBasketCardKurban = async (itemProps: any) => {
    saveCookieBox({
      ...{
        obje: (cookieBox?.obje ?? []).map((obj: any) => {
          if (obj.basket_id === itemProps.items.basket_id) {
            return {...obj, other: !obj.other}
          }

          return obj
        }),
      },
    } as CookieBoxModel)
  }

  const checkBasketCardProxy = async (itemProps: any) => {
    saveCookieBox({
      ...{
        obje: (cookieBox?.obje ?? []).map((obj: any) => {
          if (obj.basket_id === itemProps.items.basket_id) {
            return {
              ...obj,
              proxy: !obj.proxy,
              proxyError: !obj.proxy === true ? false : obj.proxyError,
            }
          }

          return obj
        }),
      },
    } as CookieBoxModel)
  }

  const updateBasketCard = async (elem: any, itemOrder: any = -1) => {
    let itemProps = props
    let item = cookieBox?.obje ?? []
    if (itemProps.items.pay_type === 6 && elem.target.name === 'piece') {
      let mon =
        props.items.monthly -
        (props.items.taksit_odenen !== undefined ? props.items.taksit_odenen : 0)
      let apmon = Math.ceil(mon / elem.target.value)
      setAutoPayMonths(autoPayMonthOpt(apmon, ' Ay'))
    }
    if (item) {
      if (
        itemProps.items.pay_type === 6 &&
        itemProps.items.monthly -
          (itemProps.items.taksit_odenen !== undefined ? itemProps.items.taksit_odenen : 0) <
          elem.target.value &&
        elem.target.name === 'piece'
      ) {
        Swal.fire({
          title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_5'}),
          text: intl.formatMessage({id: 'MAIN.CARD.WARNING_6'}),
          icon: 'warning',
          timer: 2500,
        })
        return false
      }
      if (Number(itemProps.items.info_type) === 2 && elem.target.name === 'piece') {
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              if (obj.basket_id === itemProps.items.basket_id) {
                if (elem.target.value > 0) {
                  if (Number(itemProps.items.total) < elem.target.value) {
                    const addArray = []
                    for (let ix = 0; ix < elem.target.value - Number(itemProps.items.total); ix++) {
                      addArray.push({
                        cardOrder: obj.values.length + 1,
                        piece: 1,
                        other: obj.values[0].other,
                        name: '',
                        phone: '',
                        phoneCode: 90,
                        phoneValue: '',
                        autopay: false,
                        month: 0,
                        day: 0,
                      })
                    }
                    return {
                      ...obj,
                      total: elem.target.value,
                      values: obj.values.concat(addArray),
                    }
                  } else {
                    obj.values.splice(
                      elem.target.value - 1,
                      Number(itemProps.items.total) - Number(elem.target.value)
                    )
                    return {...obj, total: elem.target.value, values: obj.values}
                  }
                }
              }

              return obj
            }),
          },
        } as CookieBoxModel)
      } else {
        if (itemOrder === -1) {
          itemOrder = itemProps.order
        }
        if (Number(itemProps.items.info_type) === 3 && elem.target.name === 'piece') {
          let addPiece = elem.target.value - itemProps.items.values[itemOrder]['piece']
          let Quantity = itemProps.items.quantity
          let Target =
            Math.round((Number(itemProps.items.target) / (genericValues?.forTL ?? 1)) * 100) / 100
          if (itemProps.items.pay_type === 4) {
            let tot = (cookieBox?.obje ?? [])
              .filter((x) => x.card_id === itemProps.items.card_id)
              .map((x) => Number(x.quantity) * Number(x.total))
              .reduce((acc, val) => acc + val, 0)
            if (Math.round(tot + addPiece * Quantity) > Target) {
              Swal.fire({
                title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_1'}),
                text: intl.formatMessage({id: 'MAIN.CARD.WARNING_1'}),
                showConfirmButton: false,
                timer: 2500,
              })
              return false
            }
          } else if (itemProps.items.pay_type === 5) {
            let tot = (cookieBox?.obje ?? [])
              .filter((x) => x.card_id === itemProps.items.card_id)
              .map((x) => Number(x.total))
              .reduce((acc, val) => acc + val, 0)
            if (Math.round(tot + addPiece) > Target) {
              Swal.fire({
                title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_1'}),
                text: intl.formatMessage({id: 'MAIN.CARD.WARNING_1'}),
                showConfirmButton: false,
                timer: 2500,
              })
              return false
            }
          }
        }
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              if (obj.basket_id === itemProps.items.basket_id) {
                let addTotal = 0
                if (elem.target.name === 'piece') {
                  addTotal = elem.target.value - obj.values[itemOrder]['piece']
                }

                obj.values[itemOrder][elem.target.name] = elem.target.value
                if (obj.values[itemOrder]['error']) {
                  if (itemProps.items.pay_type === 7) {
                    let errorCheck = 0
                    if (
                      obj.values[itemOrder]['other'] === false ||
                      obj.values[itemOrder]['name'] === '' ||
                      obj.values[itemOrder]['phone'] === ''
                    ) {
                      errorCheck++
                    }
                    if (errorCheck === 0) {
                      obj.values[itemOrder]['error'] = false
                    }
                  } else {
                    if (
                      obj.values[itemOrder]['other'] === false &&
                      obj.values[itemOrder]['autopay'] === false
                    ) {
                      obj.values[itemOrder]['error'] = false
                    } else {
                      let errorCheck = 0
                      if (
                        obj.values[itemOrder]['other'] &&
                        (obj.values[itemOrder]['name'] === '' ||
                          obj.values[itemOrder]['phone'] === '')
                      ) {
                        errorCheck++
                      }
                      if (
                        obj.values[itemOrder]['autopay'] &&
                        (obj.values[itemOrder]['month'] === 0 || obj.values[itemOrder]['day'] === 0)
                      ) {
                        errorCheck++
                      }
                      if (errorCheck === 0) {
                        obj.values[itemOrder]['error'] = false
                      }
                    }
                  }
                }

                return {...obj, total: Number(obj.total) + addTotal, values: obj.values}
              }

              return obj
            }),
          },
        } as CookieBoxModel)
      }
    }
    //formik.values.name = elem.target.value
  }
  const deleteBasketCard = async (itemProps: any) => {
    let item = cookieBox?.obje ?? []
    if (item) {
      if (Number(itemProps.items.info_type) === 1) {
        if (Number(item[itemProps.index_key].values.length) <= 1) {
          saveCookieBox({
            ...{
              obje: (cookieBox?.obje ?? []).filter((employee: any) => {
                // 👇️ remove object that has id equal to 2
                return employee.basket_id !== itemProps.items.basket_id
              }),
            },
          } as CookieBoxModel)
        } else {
          saveCookieBox({
            ...{
              obje: (cookieBox?.obje ?? []).map((obj: any) => {
                // 👇️ if id equals 2, update country property
                if (obj.basket_id === itemProps.items.basket_id) {
                  let deleteTotal = Number(obj.values[itemProps.order]['piece']) ?? 1
                  obj.values.splice(itemProps.order, 1)
                  return {...obj, total: obj.total - deleteTotal, values: obj.values}
                }
                // 👇️ otherwise return object as is
                return obj
              }),
            },
          } as CookieBoxModel)
        }
      } else if (Number(itemProps.items.info_type) === 2 || 3) {
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).filter((employee: any) => {
              // 👇️ remove object that has id equal to 2
              return employee.basket_id !== itemProps.items.basket_id
            }),
          },
        } as CookieBoxModel)
      }
    }
  }
  const autoPayMonthOpt = (len: any = 36, optTag: any = 'Ay') => {
    const opts = []
    for (let opt = 1; opt <= len; opt++) {
      opts.push(<option value={opt}>{opt + optTag}</option>)
    }
    return opts
  }
  const [autoPayMonths, setAutoPayMonths] = useState<any>('')
  useEffect(() => {
    if (props.items.pay_type === 6) {
      let mon =
        props.items.monthly -
        (props.items.taksit_odenen !== undefined ? props.items.taksit_odenen : 0)
      let apmon = Math.ceil(mon / props.items.total)
      setAutoPayMonths(autoPayMonthOpt(apmon, ' Ay'))
    } else {
      setAutoPayMonths(autoPayMonthOpt(36, ' Ay'))
    }
  }, [])

  const [allForOne, setAllForOne] = useState<boolean>(false)
  const checkAllForOne = async () => {
    let cname = ''
    let cphone = ''
    let cphoneCode = ''
    let cphoneValue = ''
    if (currentUser && Number(currentUser.authority) !== 1) {
      cname = currentUser.adi + ' ' + currentUser.soyadi
      cphone = String(currentUser.accountPhoneNumber)
      const numaraStr = cphone
      const sonOnHane = parseInt(numaraStr.substring(numaraStr.length - 10))
      const kalanKisim = parseInt(numaraStr.substring(0, numaraStr.length - 10))
      cphoneCode = String(kalanKisim)
      cphoneValue = String(sonOnHane)
    } else {
      cname = props.customer_name
      cphoneCode = props.customer_phoneCode
      cphoneValue = props.customer_phoneValue
      cphone = props.customer_phone
    }
    if (allForOne === false) {
      if (cname !== '' && cphone !== '') {
        setAllForOne(!allForOne)
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              if (obj.basket_id === props.items.basket_id) {
                for (let i = 0; i < obj.values.length; i++) {
                  obj.values[i].name = cname
                  obj.values[i].phone = cphone
                  obj.values[i].phoneCode = cphoneCode
                  obj.values[i].phoneValue = cphoneValue
                }

                return {...obj, values: obj.values}
              }

              return obj
            }),
          },
        } as CookieBoxModel)
      } else {
        Swal.fire({
          title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_5'}),
          text: intl.formatMessage({id: 'MAIN.CARD.WARNING_7'}),
          icon: 'warning',
          timer: 2500,
        })
      }
    } else {
      setAllForOne(!allForOne)
      saveCookieBox({
        ...{
          obje: (cookieBox?.obje ?? []).map((obj: any) => {
            if (obj.basket_id === props.items.basket_id) {
              for (let i = 0; i < obj.values.length; i++) {
                obj.values[i].name = ''
                obj.values[i].phone = ''
              }
              return {...obj, values: obj.values}
            }

            return obj
          }),
        },
      } as CookieBoxModel)
    }
  }
  const financial = (x: any) => Number.parseFloat(x)
  const [countDonate, setCountDonate] = useState<number | null>(null)
  const textInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    let countDon =
      Number(props.items.info_type) === 1 || Number(props.items.info_type) === 3
        ? props.items.values[props.order].piece
        : Number(props.items.total)
    if (countDon != countDonate && countDonate === null) {
      setCountDonate(countDon)
    }
  }, [props])
  const countCheck = (elem: any) => {
    if (Number(elem.target.value) > 0) {
      updateBasketCard(elem)
    } else {
      textInputRef?.current?.focus()
      Swal.fire({
        title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_5'}),
        text: intl.formatMessage({id: 'MAIN.CARD.WARNING_8'}),
        icon: 'warning',
        timer: 2500,
      })
      return false
    }
  }
  return (
    <div className='card card-custom  shadow mb-5' style={{width: '100%'}}>
      <div className='card-body'>
        <Row className='justify-content-between'>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            xxl={3}
            className='hstack gap-3 d-none d-sm-none d-md-block'
            style={{ borderRight: '2px solid #919191' }}
          >
            <div className='w-100 h-100 d-flex flex-row-reverse align-items-center'>
              <img
                className='img-fluid '
                style={{width: '100%', height: '100%', borderRadius: '8px', maxHeight: '140px'}}
                src={`${process.env.REACT_APP_MEDIA_API_URL}${props.items.icon}`}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
            <h3>{langData['DB.DONATE_LIST.VALUE_' + props.items.card_id]}</h3>
            {Number(props.items.info_type) === 1 || Number(props.items.info_type) === 3 ? (
              <>
                <div className='mt-5 mb-3'>
                  <div className='form-check form-check-custom form-check-solid '>
                    <input
                      ref={ref}
                      checked={props.items.values[props.order].other || false}
                      onChange={() => checkBasketCard(props)}
                      className='form-check-input accordion collapsed'
                      type='checkbox'
                      value=''
                      id={`flexCheckDefault1${props.items.basket_id}`}
                    />
                    <label
                      htmlFor={`flexCheckDefault1${props.items.basket_id}`}
                      className='form-check-label'
                    >
                      {intl.formatMessage({id: 'MAIN.BASKET.CHECKBOX_1'})}
                    </label>
                  </div>

                  {props.items.values[props.order].other ? (
                    <div className='row bg-gray-200 rounded ms-0 mt-2 '>
                      <div className='col-xs-12 col-md-6 p-2'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'MAIN.GENERAL.NAME'})}
                        </label>
                        <input
                          ref={ref}
                          type='text'
                          className={clsx(
                            'form-control form-control-sm form-control-solid',
                            {
                              'is-invalid':
                                props.items.values[props.order].error &&
                                props.items.values[props.order].name === '',
                            },
                            {
                              'is-valid': props.items.values[props.order].name !== '',
                            }
                          )}
                          placeholder='İsim Soyisim Giriniz'
                          autoComplete='off'
                          name='name'
                          value={props.items.values[props.order].name}
                          onChange={(e) => updateBasketCard(e)}
                        />
                        {props.items.values[props.order].error &&
                          props.items.values[props.order].name === '' && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>
                                  {intl.formatMessage({id: 'MAIN.BASKET.REQUIRED_1'})}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className='col-xs-12 col-md-6 p-2'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'MAIN.GENERAL.PHONE'})}
                        </label>
                        <PhoneInputLocal2
                          size={'sm'}
                          index={-1}
                          phone={props.items.values[props.order].phone}
                          value={props.items.values[props.order].phoneValue}
                          error={props.items.values[props.order].error}
                          code={props.items.values[props.order].phoneCode}
                          updateBasketCard={updateBasketCard}
                        />
                        {/* <PhoneInput
                          enableSearch
                          containerClass={''}
                          inputStyle={{ backgroundColor: '#F5F8FA', width: '100%', height: '50%' }}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                            ref: ref
                          }}
                          // {...formik.getFieldProps('phone')}
                          value={props.items.values[props.order].phone}
                          country={'tr'}
                          onChange={(e) => updateBasketCard({ target: { name: 'phone', value: e } }, props)}
                        /> */}
                        {props.items.values[props.order].error &&
                          props.items.values[props.order].phone === '' && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>
                                  {intl.formatMessage({id: 'MAIN.BASKET.REQUIRED_2'})}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* OTOMATİK ÖDEME TALİMATI START  ???????*/}
                {Number(props.items.info_type) === 1 ? (
                  <div className='mb-3'>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        ref={ref}
                        className={clsx(
                          'form-check-input',
                          currentUser && Number(currentUser.authority) !== 1 ? '' : 'disabled'
                        )}
                        type='checkbox'
                        value=''
                        id={`flexCheckDefault2${props.items.basket_id}`}
                        checked={props.items.values[props.order].autopay || false}
                        onChange={() => checkBasketCard(props, 1)}
                      />
                      <label
                        htmlFor={`flexCheckDefault2${props.items.basket_id}`}
                        className='form-check-label'
                      >
                        {intl.formatMessage({id: 'MAIN.BASKET.CHECKBOX_2'})}
                      </label>
                    </div>
                    {props.items.values[props.order].autopay ? (
                      <div className='row bg-gray-200 rounded ms-0 mt-2 '>
                        <div className='col-xs-12 col-md-6 p-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            {intl.formatMessage({id: 'MAIN.BASKET.MONTH.SELECT'})}
                          </label>
                          <select
                            ref={ref}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid':
                                  props.items.values[props.order].error &&
                                  props.items.values[props.order].month === 0,
                              },
                              {
                                'is-valid': props.items.values[props.order].month !== 0,
                              }
                            )}
                            title={intl.formatMessage({id: 'MAIN.BASKET.MONTH.SELECT'})}
                            autoComplete='off'
                            name='month'
                            onChange={(e) => updateBasketCard(e)}
                            value={props.items.values[props.order].month}
                          >
                            <option>{intl.formatMessage({id: 'MAIN.GENERAL.SELECT'})}</option>
                            {autoPayMonths}
                          </select>
                          <small>{intl.formatMessage({id: 'MAIN.BASKET.INFO_1'})}</small>
                          {props.items.values[props.order].error &&
                            props.items.values[props.order].month === 0 && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>
                                    {intl.formatMessage({id: 'MAIN.BASKET.REQUIRED_3'})}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className='col-xs-12 col-md-6 p-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            {intl.formatMessage({id: 'MAIN.GENERAL.SELECT.DAY'})}
                          </label>
                          <select
                            ref={ref}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid':
                                  props.items.values[props.order].error &&
                                  props.items.values[props.order].day === 0,
                              },
                              {
                                'is-valid': props.items.values[props.order].day !== 0,
                              }
                            )}
                            title='Gün seçiniz'
                            autoComplete='off'
                            name='day'
                            onChange={(e) => updateBasketCard(e)}
                            value={props.items.values[props.order].day}
                          >
                            <option>{intl.formatMessage({id: 'MAIN.GENERAL.SELECT'})}</option>
                            {autoPayMonthOpt(36, '.Gün')}
                          </select>
                          <small>{intl.formatMessage({id: 'MAIN.BASKET.INFO_2'})}</small>
                          {props.items.values[props.order].error &&
                            props.items.values[props.order].day === 0 && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>
                                    {intl.formatMessage({id: 'MAIN.BASKET.REQUIRED_4'})}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {/* OTOMATİK ÖDEME TALİMATI END */}
              </>
            ) : (
              <>
                <div className='mt-5 mb-3'>
                  <div className='form-check form-check-custom form-check-solid '>
                    <input
                      ref={ref}
                      checked={props.items.other || false}
                      onChange={() => checkBasketCardKurban(props)}
                      className='form-check-input accordion collapsed'
                      type='checkbox'
                      value=''
                      id={`flexCheckDefault1${props.items.basket_id}`}
                    />
                    <label
                      htmlFor={`flexCheckDefault1${props.items.basket_id}`}
                      className='form-check-label'
                    >
                      {intl.formatMessage({id: 'MAIN.BASKET.CHECKBOX_1'})}
                    </label>
                  </div>
                  <br></br>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      ref={ref}
                      checked={props.items.proxy || false}
                      onChange={() => checkBasketCardProxy(props)}
                      className={clsx(
                        'form-check-input accordion collapsed',
                        {
                          'is-invalid': props.items.proxyError && props.items.proxy === false,
                        },
                        {
                          'is-valid': props.items.proxy !== false,
                        }
                      )}
                      type='checkbox'
                      id={`flexCheckDefault${props.items.basket_id + '-' + 0}`}
                    />
                    <label
                      htmlFor={`flexCheckDefault${props.items.basket_id + '-' + 0}`}
                      className='form-check-label'
                    >
                      {intl.formatMessage({id: 'MAIN.BASKET.CHECKBOX_4'}).replace('{name}',`${process.env.REACT_APP_CONTROL}`)}
                    </label>
                  </div>
                  {props.items.other ? (
                    <>
                      <br></br>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          ref={ref}
                          checked={allForOne}
                          onChange={() => checkAllForOne()}
                          className={clsx('form-check-input accordion collapsed')}
                          type='checkbox'
                          id={`flexCheckAllDefault${0}`}
                        />
                        <label htmlFor={`flexCheckAllDefault${0}`} className='form-check-label'>
                          {intl.formatMessage({id: 'MAIN.BASKET.CHECKBOX_3'})}
                        </label>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {props.items.other &&
                  props.items.values.map((val: any, index: any) => (
                    <div className='mt-5 mb-3' key={index}>
                      {Number(props.items.info_type) === 2 ? (
                        <>
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <label className='form-label fw-bolder text-dark fs-6'>
                                {intl.formatMessage({id: 'MAIN.GENERAL.NAME'})}
                              </label>
                              <input
                                ref={ref}
                                type='text'
                                className={clsx(
                                  'form-control form-control-sm form-control-solid',
                                  {
                                    'is-invalid':
                                      props.items.values[index].error &&
                                      props.items.values[index].name === '',
                                  },
                                  {
                                    'is-valid': props.items.values[index].name !== '',
                                  }
                                )}
                                placeholder={intl.formatMessage({id: 'MAIN.BASKET.INFO_3'})}
                                autoComplete='off'
                                name='name'
                                value={val.name}
                                onChange={(e) => updateBasketCard(e, index)}
                              />
                              {props.items.values[index].error &&
                                props.items.values[index].name === '' && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>
                                        {intl.formatMessage({id: 'MAIN.BASKET.REQUIRED_1'})}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <label className='form-label fw-bolder text-dark fs-6'>
                                {intl.formatMessage({id: 'MAIN.GENERAL.PHONE'})}
                              </label>
                              <PhoneInputLocal2
                                size={'sm'}
                                index={index}
                                phone={val.phone}
                                value={val.phoneValue}
                                error={val.error}
                                code={val.phoneCode}
                                updateBasketCard={updateBasketCard}
                              />
                              {/* <PhoneInput
                            enableSearch
                            localization={tr}
                            containerClass={''}
                            inputStyle={{ backgroundColor: '#F5F8FA', width: '100%', height: '50%' }}
                            inputProps={{
                              name: 'phone',
                              required: true,
                              autoFocus: true,
                              ref: ref
                            }}
                            {...formik.getFieldProps('phone')}
                            value={props.items.values[index].phone}
                            country={'tr'}
                            onChange={(e) => updateBasketCard({ target: { name: 'phone', value: e } }, props, index)}
                          // onChange={(phone: any) => formik.values.phone = phone}
                          /> */}
                              {props.items.values[index].error &&
                                props.items.values[index].phone === '' && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>
                                        {intl.formatMessage({id: 'MAIN.BASKET.REQUIRED_2'})}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </Col>
                          </Row>
                          <hr style={{border: '2px solid rgb(85, 85, 85)'}} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
              </>
            )}
          </Col>
          <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className='pt-4'>
            <Row>
              <Col xs={6} sm={6} md={8} lg={9} xl={9} xxl={9}>
                <h3 className='mt-1 ms-1  text-center'>
                  {currencyFormat(
                    financial(props.items.quantity * props.items.values[props.order].piece)
                  )}{' '}
                  {genericValues?.currSym ?? '₺'}
                </h3>
              </Col>
              <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={3}>
                <div className='ms-0 mt-n5'>
                  <a
                    //href='#'
                    //   onClick={() => deleteBasketCard(index, val.basket_id)}
                    onClick={() => deleteBasketCard(props)}
                    className='btn btn-link btn-color-danger btn-active-color-primary float-end '
                  >
                    <i className='bi bi-dash-circle fs-3x '></i>
                  </a>
                </div>
              </Col>
              <Col xs={6} sm={6} md={8} lg={12} xl={12} xxl={12}>
                <div className=' text-center '>
                  <span className=''>
                    {' '}
                    {currencyFormat(financial(props.items.quantity))}{' '}
                    {genericValues?.currSym ?? '₺'} <br></br>x
                  </span>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}></Col>
              <Col xs={7} sm={5} md={8} lg={12} xl={12} xxl={12}>
                <div className=' text-center '>
                  <div className='input-group mt-2 p-0'>
                    <button
                      type='button'
                      className='btn btn-icon btn-link btn-color-bereket btn-active-color-primary btn-sm'
                      onClick={() => {
                        countCheck({target: {name: 'piece', value: (countDonate ? (countDonate - 1 > 0 ? countDonate - 1 : 1) : 1)}})
                        setCountDonate((prev) => (prev ? (prev - 1 > 0 ? prev - 1 : 1) : 1))
                      }}
                    >
                      <i className={'bi fs-2x bi-dash-circle'}></i>
                    </button>

                    <input
                      ref={textInputRef}
                      min={1}
                      max={
                        props.items.monthly > 0
                          ? props.items.monthly -
                            (props.items.taksit_odenen !== undefined
                              ? props.items.taksit_odenen
                              : 0)
                          : 999999999
                      }
                      style={{
                        width: '3rem',
                        height: '30px',
                        position: 'unset',
                        display: 'inline',
                      }}
                      className='form-control  mx-2 mt-1'
                      type={'number'}
                      onWheel={() => (document.activeElement as HTMLElement).blur()}
                      name='piece'
                      value={(countDonate ?? '') != 0 ? countDonate ?? '' : ''}
                      onChange={(e) => setCountDonate(Number(e.target.value))}
                      onBlur={(e) => countCheck(e)}
                    />
                    <button
                      type='button'
                      className='btn btn-icon btn-link btn-color-bereket btn-active-color-primary btn-sm'
                      onClick={() => {
                        countCheck({target: {name: 'piece', value: countDonate ? countDonate + 1 : 1}})
                        setCountDonate((prev) => (prev ? prev + 1 : 1))
                      }}
                    >
                      <i className={'bi fs-2x bi-plus-circle'}></i>
                    </button>
                  </div>
                  {Number(props.items.pay_type === 6) ? (
                    <div>
                      {props.items.taksit_odenen !== undefined ? (
                        <small>
                          {intl.formatMessage({id: 'MAIN.BASKET.INFO_4'})} :
                          <b>
                            {props.items.taksit_odenen}{' '}
                            {intl.formatMessage({id: 'MAIN.CARD.MONTH'})}{' '}
                          </b>
                          {intl.formatMessage({id: 'MAIN.BASKET.INFO_5'})} :
                          <b>
                            {props.items.monthly - props.items.taksit_odenen}{' '}
                            {intl.formatMessage({id: 'MAIN.CARD.MONTH'})}
                          </b>
                        </small>
                      ) : (
                        <small>
                          {intl.formatMessage({id: 'MAIN.CARD.SPONSOR.TIME'})} :<br></br>
                          <b>
                            {props.items.monthly} {intl.formatMessage({id: 'MAIN.CARD.MONTH'})}
                          </b>
                        </small>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BasketCard
