import {GenericValuesModel} from './_models'
import { getLangData } from './_requests'

const VALUES_LOCAL_STORAGE_KEY = 'generic-values'
const getGenericValues = (): GenericValuesModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(VALUES_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const genericValues: GenericValuesModel = JSON.parse(lsValue) as GenericValuesModel
    if (genericValues) {
      // You can easily check auth_token expiration also
      return genericValues
    }
  } catch (error) {
    console.error('COOKIE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setGenericValues = (cookieBox: GenericValuesModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(cookieBox)
    localStorage.setItem(VALUES_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('COOKIE LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeGenericValues = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(VALUES_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('COOKIE LOCAL STORAGE REMOVE ERROR', error)
  }
}

const requestLang = async (lang: string) => {
  const data = await getLangData(lang).then((res) => {
    
    if (getGenericValues === undefined) {
      setGenericValues({ currency: 3, currSym: '₺', currShort: 'TRY', forTL: 1, langJson: res.data } as GenericValuesModel)
    } else {
      setGenericValues({ ...getGenericValues, langJson: res.data  } as GenericValuesModel)
    }

  })
}

export {getGenericValues, setGenericValues, removeGenericValues, VALUES_LOCAL_STORAGE_KEY,requestLang}
