import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { Categories, UsersQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_DATABASE_API_URL
const CATEGORIES_URL = `${API_URL}/settings`

const getLandPhones = (): Promise<any> => {
  const all = axios
    .get(`${CATEGORIES_URL}/getLandPhones`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return all
}

const createLandPhone = (data: any): Promise<any | undefined> => {

  const all = axios
    .post(`${CATEGORIES_URL}/createLandPhone`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
  return all

}

const editLandPhone = (data: any): Promise<any | undefined> => {
  return (
    axios
      .post(`${CATEGORIES_URL}/editLandPhone`, data)
      .then((response: Response<any>) => response.data)
  )
}

const removeLandPhone = (id: ID): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/removeLandPhone/${id}`)
    .then((response: Response<any>) => response.data)

  return all
}

const getDonateList = (): Promise<any> => {
  const all = axios
    .get(`${CATEGORIES_URL}/getDonateList`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return all
}

const createRecommendedDonate = (data: any): Promise<any | undefined> => {

  const all = axios
    .post(`${CATEGORIES_URL}/createRecommendedDonate`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
  return all

}

const getRecommendedDonate = (): Promise<any> => {
  const all = axios
    .get(`${CATEGORIES_URL}/getRecommendedDonate`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return all
}

const editRecommendedDonate = (data: any): Promise<any | undefined> => {
  return (
    axios
      .post(`${CATEGORIES_URL}/editRecommendedDonate`, data)
      //.then((response: AxiosResponse<Response<Categories>>) => response.data)
      .then((response: Response<any>) => response.data)
  )
}

const removeRecommendedDonate = (id: ID): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/removeRecommendedDonate/${id}`)
    .then((response: Response<any>) => response.data)

  return all
}


const getCategorieById = (id: ID): Promise<Categories | undefined> => {
  const all = axios
    .get(`${CATEGORIES_URL}/single_categorie/${id}`)
    .then((response: Response<Categories>) => response.data)
  return all
}



const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${CATEGORIES_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const HeaderItems = () => {
  const all = axios
    .post(`${CATEGORIES_URL}/HeaderItems`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
  return all
}
const HeaderItemDelete = (id: ID): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/HeaderItemDelete/${id}`)
    .then((response: Response<any>) => response.data)

  return all
}
const createHeaderItem = (data: any): Promise<any | undefined> => {

  const all = axios
    .post(`${CATEGORIES_URL}/createHeaderItem`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
  return all

}
const HeaderSubItemAddToList = (data: any): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/HeaderSubItemAddToList`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
  return all

}
const HeaderItemUpdate = (data: any): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/HeaderItemUpdate`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
  return all

}
const HeaderSubItemUpdate = (data: any): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/HeaderSubItemUpdate`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
  return all

}
const HeaderSubItemDelete = (id: ID): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/HeaderSubItemDelete/${id}`)
    .then((response: Response<any>) => response.data)

  return all
}
const addHeaderDesignPhotos = (data: any) => {
  const all = axios
    .post<any>(`${CATEGORIES_URL}/addHeaderDesignPhotos`, data)
    .then((d: AxiosResponse<any>) => d.data)
  return all
}
const addFooterDescription = (data: any) => {
  const all = axios
    .post<any>(`${CATEGORIES_URL}/addFooterDescription`,  {data} )
    .then((d: AxiosResponse<any>) => d.data)
  return all
}
const TitleList = async () => {
  const all = axios
    .post(`${CATEGORIES_URL}/TitleList`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
  return await all
}
const addFooterDetails = (data: any) => {
  const all = axios
    .post<any>(`${CATEGORIES_URL}/addFooterDetails`, { data })
    .then((d: AxiosResponse<any>) => d.data)
  return all
}
const listSubTitlesByTitleId = (id: ID) => {  
  const all = axios
    .post(`${CATEGORIES_URL}/listSubTitlesByTitleId/${id}`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
  return all
}
const getFooterDescriptions = () => {
  const all = axios
    .post(`${CATEGORIES_URL}/getFooterDescriptions`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
  return all
}
const UpdateFooterSubTitle = (data: any): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/UpdateFooterSubTitle`, data)
    .then((response: Response<any>) => response.data)

  return all
}
const getPhotosHeader = () => {
  const all = axios
    .post(`${CATEGORIES_URL}/getPhotosHeader`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
  return all
}
const DeleteFooterListById = (id: ID): Promise<any | undefined> => {
  const all = axios
    .post(`${CATEGORIES_URL}/DeleteFooterListById/${id}`)
    .then((response: Response<any>) => response.data)

  return all
}

export {
  getLandPhones,
  removeLandPhone,
  createLandPhone,
  getPhotosHeader,
  editLandPhone,
  HeaderItems,
  DeleteFooterListById,
  HeaderItemUpdate,
  UpdateFooterSubTitle,
  createHeaderItem,
  HeaderSubItemUpdate,
  TitleList,
  HeaderItemDelete,
  HeaderSubItemDelete,
  getDonateList,
  addFooterDescription,
  getFooterDescriptions,
  addFooterDetails,
  listSubTitlesByTitleId,
  addHeaderDesignPhotos,
  createRecommendedDonate,
  getRecommendedDonate,
  editRecommendedDonate,
  removeRecommendedDonate,
  HeaderSubItemAddToList,
  deleteSelectedUsers,
  getCategorieById,


}
