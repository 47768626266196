import React, {FC, useEffect, useRef, useState} from 'react'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import {Formik, Form, FormikValues} from 'formik'
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../../setup'
// import { addCompany } from '../redux/AuthCRUD'
import {Router, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ScrollTopComponent, StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {
  ICreateAccount,
  createAccountSchemas,
  inits,
} from './../../../wizards/components/CreateAccountWizardHelper'
import {toAbsoluteUrl} from './../../../../../_metronic/helpers/AssetHelpers'
import Paymodal from './steps/Paymodal'
import CreditPaymentModal from './steps/creditpayment'
import './steps/bagis.css'
import {credit_payment_first} from '../../core/_requests'
import {CookieBoxModel, useAuth} from '../../../auth'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

const BasketPage = (degerler: any) => {
  const intl = useIntl()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [hash_id_statu, setHash_id_statu] = useState(0)
  const [paymentModalShow, setPaymentModalShow] = useState(false)
  const [butonkapat, setButonkapat] = useState<any>(false)
  const [payApprove, setPayApprove] = useState(false)
  const [Step2Vals, setStep2Vals] = useState({...inits})

  useEffect(() => {}, [Step2Vals])

  const {cookieBox, saveCookieBox, genericValues, saveGenericValues, currentUser} = useAuth()
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    setPayApprove(false)
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    stepper.current.goPrev()
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }
  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    setButonkapat(false)
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 1) {
      let errorCount = 0
      ;(cookieBox?.obje ?? []).map((items: any, index: any) => {
        if (items.pay_type === 7 && items.proxy === false) {
          errorCount++
          saveCookieBox({
            ...{
              obje: (cookieBox?.obje ?? []).map((obj: any) => {
                if (obj.basket_id === items.basket_id) {
                  return {...obj, proxyError: true}
                }

                return obj
              }),
            },
          } as CookieBoxModel)
        }
        items.values.map((val: any, vlin: any) => {
          let errorCheck = 0

          if (val.other === true || (items.pay_type === 7 && items.other)) {
            if (items.pay_type !== 7 && val.other === false) {
              errorCount++
              errorCheck++
            }
            if (val.name === '' || val.phone === '') {
              errorCount++
              errorCheck++
            }
          }
          if (val.autopay === true) {
            if (val.month === 0 || val.day === 0) {
              errorCount++
              errorCheck++
            }
          }
          if (errorCheck > 0) {
            saveCookieBox({
              ...{
                obje: (cookieBox?.obje ?? []).map((obj: any) => {
                  if (obj.basket_id === items.basket_id) {
                    obj.values[vlin]['error'] = true
                    return {...obj, values: obj.values}
                  }

                  return obj
                }),
              },
            } as CookieBoxModel)
          }
        })
      })
      if (errorCount > 0) {
        Swal.fire({
          title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_4'}),
          text: intl.formatMessage({id: 'MAIN.CARD.WARNING_4'}),
          icon: 'warning',
          showConfirmButton: false,
          timer: 1500,
        })
        return false
      }
    }

    if (stepper.current.currentStepIndex === 2) {
   
      //setModalShow(true);
      //await setUserValues(values, cookieBox?.obje)
        if (
        Step2Vals.toggle === false &&
        (Step2Vals.name === '' ||
          Step2Vals.number === '' ||
          Step2Vals.cvc === '' ||
          Step2Vals.expiry === '')
      ) {
        Swal.fire({
          title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_4'}),
          text: intl.formatMessage({id: 'MAIN.CARD.WARNING_4'}),
          icon: 'warning',
          showConfirmButton: false,
          timer: 1500,
        })
      } else if (
        Step2Vals.toggle == false &&
        (Step2Vals.number.length < 16 || Step2Vals.expiry.length < 5)
      ) {
        Swal.fire({
          title: intl.formatMessage({id: 'MAIN.CARD.WARNING_HEAD_CARDNUMBER'}),
          text: intl.formatMessage({id: 'MAIN.CARD.WARNING_TEXT_CARDNUMBER'}),
          icon: 'warning',
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        setButonkapat(true)

        Step2Vals.currencyInfo = genericValues
        const {data: auth} = await credit_payment_first(Step2Vals)

        if (auth.durum == 'havale') {
          saveCookieBox(undefined)
          stepper.current.goto(3)
        } else if (auth.durum == 'kkart') {
          //window.open(`${process.env.REACT_APP_API_URL}creditcard/test/${auth.rand_hash}`, '_self', 'noreferrer');
          setHash_id_statu(auth.rand_hash)
          setPaymentModalShow(true)
        }
        return
      }
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      if (stepper.current.currentStepIndex !== 2) {
        stepper.current.goNext()
      }
    } else {
    }
  }
  useEffect(() => {
    if (payApprove) {
      setModalShow(false)
      if (!stepper.current) {
        return
      }
      stepper.current.goNext()
    }
  }, [payApprove])
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    window.addEventListener('message', (ev: MessageEvent<{type: string; status: string}>) => {
      if (typeof ev.data !== 'object') return
      if (!ev.data.type) return
      if (ev.data.type !== 'button-click') return
      if (!ev.data.status) return
      if (ev.data.status == 'success') {
        setPaymentModalShow(false)
        // burada ödeme başarı onaylandı oluyor step 3 e geçirilir.
        saveCookieBox(undefined)
        stepper.current?.goto(3)
      }
    })
  }, [window])
  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div
            ref={stepperRef}
            className='stepper stepper-links d-flex flex-column '
            id='kt_create_account_stepper'
          >
            <div
              style={{display: 'inline-flex', flexDirection: 'row', flexWrap: 'nowrap'}}
              className='stepper-nav align-items-center  '
            >
              <div
                className='stepper-item current text-center ms-n2 '
                data-kt-stepper-element='nav'
              >
                <h6 className=''>{intl.formatMessage({id: 'MAIN.BASKET.MENU_1'})}</h6>
                {/* {`${process.env.REACT_APP_MEDIA_API_URL}user.png`} */}
                <div className='justify-content-between'>
                  <img
                    className='text-center mt-5'
                    style={{width: '50px', height: '50px'}}
                    src={`${toAbsoluteUrl('/media/payment/text.png')}`}
                  />
                </div>
              </div>
              <div className='stepper-item text-center ms-n2' data-kt-stepper-element='nav'>
                <h6 className=''>{intl.formatMessage({id: 'MAIN.BASKET.MENU_2'})}</h6>
                <img
                  className='text-center mt-5'
                  style={{width: '50px', height: '50px'}}
                  src={`${toAbsoluteUrl('/media/payment/payment.png')}`}
                />
                {/* <div className="separator border-secondary my-10"></div> */}
              </div>
              <div
                className='stepper-item text-center ms-n2 me-xs-n15'
                data-kt-stepper-element='nav'
              >
                <h6 className=''>{intl.formatMessage({id: 'MAIN.BASKET.MENU_3'})}</h6>
                <img
                  className='text-center mt-5'
                  style={{width: '50px', height: '50px'}}
                  src={`${toAbsoluteUrl('/media/payment/heart1.png')}`}
                />
                {/* <div className="separator border-secondary my-10"></div> */}
              </div>
            </div>
            <Formik
              validationSchema={currentSchema}
              initialValues={Step2Vals}
              onSubmit={submitStep}
            >
              {(form) => (
                <Form
                  className='pb-10'
                  id='kt_create_account_form'
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <div className='current ' data-kt-stepper-element='content'>
                    <Step1 setStep2Vals={setStep2Vals} form={form} Step2Vals={Step2Vals} />
                  </div>
                  <div data-kt-stepper-element='content' className='w-100'>
                    <Step2
                      intl={intl}
                      currentUser={currentUser}
                      form={form}
                      setStep2Vals={setStep2Vals}
                      Step2Vals={Step2Vals}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Step3 form={form} sirasi={stepper.current?.currentStepIndex} />
                  </div>
                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      {stepper.current?.currentStepIndex !== 3 ? (
                        <>
                          <button
                            style={{backgroundColor: '#939393'}}
                            onClick={prevStep}
                            type='button'
                            className='cssbuttons-io btn btn-sm text-white  me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            {intl.formatMessage({id: 'MAIN.GENERAL.BACK'})}
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div>
                      {stepper.current?.currentStepIndex == 2 ? (
                        <>
                          <button
                            // disabled={butonkapat}
                            type='submit'
                            className='cssbuttons-io btn btn-sm text-white  me-3 bg-one  '
                          >
                            <span className='indicator-label'>
                              {intl.formatMessage({id: 'MAIN.GENERAL.COMPLETE'})}
                              {/* {!isSubmitButton && 'İleri'}
                            {isSubmitButton && 'Gönder'} */}
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-3 ms-2 me-0'
                              />
                            </span>
                          </button>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div>
        <Paymodal
          show={modalShow}
          onHide={() => setModalShow(false)}
          //onSubmitPay={() => setPayApprove(true)}
        />
        <CreditPaymentModal
          hash_id={hash_id_statu}
          show={paymentModalShow}
          onHide={() => {
            setPaymentModalShow(false)
          }}
          //onSubmitPay={() => setPayApprove(true)}
        />
      </div>
    </div>
  )
}

export {BasketPage}
