import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {AsideMenu} from './AsideMenu'
import {AsideUserMenu} from '../../../partials'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'

const AsideDefault = () => {
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  
  return (
    <>
      <div
        id='kt_aside'
        className={clsx('aside py-4 bg-one ', classes.aside.join(' '))}
        data-kt-drawer='true'
        data-kt-drawer-name='aside'
        data-kt-drawer-activate='{default: true, lg: false}'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'200px', '250px': '250px'}"
        data-kt-drawer-direction='start'
        data-kt-drawer-toggle='#kt_aside_toggle'
      >
        {/* begin::Brand */}
        <div className='aside-logo justify-content-center' id='kt_aside_logo'>
          {/* begin::Logo */}
          <Link to=''>
            {mode === 'light' && (
              <div className='justify-content-center align-content-center text-center'>
                <img
                  alt='Logo'
                  style={{height: '90px',width:'auto'}}
                  src={`${process.env.REACT_APP_MEDIA_API_URL}static/dashboard-logo.png`}
                />
                <br />
              </div>
            )}
          </Link>
          {/* end::Logo */}
        </div>
        {/* end::Brand */}

        {/* begin::Aside menu */}
        <div id='kt_aside_menu' className='aside-menu flex-column-fluid ps-2  '>
          <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
        </div>
        {/* end::Aside menu */}

        {/* begin::Footer */}
        <div className='aside-footer flex-column-auto px-9 ' id='kt_aside_footer'>
          <AsideUserMenu />
        </div>
        {/* end::Footer */}
      </div>
    </>
  )
}

export {AsideDefault}
