import * as Yup from 'yup'

export interface ICreateAccount {

  customer_name: string
  // phone:string
  customer_phone: string
  customer_phoneValue: string
  customer_phoneCode: number
  note: string
  name: string
  expiry: string
  expiryMonth: string
  expiryYear: string
  number: string
  cvc: string | undefined
  amount: number
  cardobject: []
  toggle: boolean
  focus: any
  currencyInfo: {} | undefined
}

const createAccountSchemas = [

  Yup.object({
    customer_name: Yup.string().required('İsim zorunlu alan'),
    customer_phone: Yup.string().required('Telefon zorunlu alan'),
  }),

]
var inits: ICreateAccount = {
  name: '',
  expiry: '',
  expiryMonth: '',
  expiryYear: '',
  number: '',
  cvc: '',
  focus: "",
  note: '',
  amount: 0,
  customer_name: '',
  customer_phone: '',
  customer_phoneValue: '',
  customer_phoneCode: 90,
  cardobject: [],
  toggle: false,
  currencyInfo: {}
}

if (process.env.REACT_APP_API_URL=="http://localhost/bagis_modul/api/") {
   inits = {
    name: 'asdasd',
    expiry: '12/27',
    expiryMonth: '12',
    expiryYear: '27',
    number: '4506344103118942',
    cvc: '809',
    focus: "name",
    note: 'selaaaam',
    amount: 0,
    customer_name: '',
    customer_phone: '',
    customer_phoneValue: '',
    customer_phoneCode: 90,
    cardobject: [],
    toggle: false,
    currencyInfo: {}
  }
}


export { createAccountSchemas, inits }
