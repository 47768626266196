/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, getPhotos} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import '../../guest/styles/button.scss'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Telefon veya Mail Zorunlu'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Şifre Zorunlu'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [photos, setPhotos] = useState<any>([])
  const getPhotosLogin = () => {
    // @ts-ignore
    getPhotos().then((res: any) => {
      setPhotos(res.data)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        if (auth.islem === 'basarili') {
          saveAuth(auth)
          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)
          setLoading(false)
        } else if (auth.islem === 'hatalar') {
          console.error('hatalar')
          saveAuth(undefined)
          setStatus('Kullanıcı adı veya şifre hatalı..')
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Sunucu Hatası')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    getPhotosLogin()
  }, [])
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <img
          alt='Logo'
         
          src={`${process.env.REACT_APP_MEDIA_API_URL}headerImages/${photos.photo_url}`}
          onError={({currentTarget}) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = `${process.env.REACT_APP_MEDIA_API_URL}static/header-logo.png`
          }}
          // src={toAbsoluteUrl('/media/logos/bereket-logo.png')}
          className='theme-light-show h-45px'
        ></img>
        {/* <h1 className='text-dark mb-3'>Sisteme Giriş Yap</h1> */}
        <div className='text-gray-400 fw-bold fs-4'>
          {/* New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link> */}
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Telefon veya Mail Adresi</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Şifre</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='text-end mt-n5 mb-4'>
        <Link to='/auth/forgot-password'>
          <span className='fw-bold ' style={{cursor: 'pointer'}}>
            Şifremi Unuttum
          </span>
        </Link>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          style={{borderRadius: ' 0px 15px 0px 15px',  color: '#fff'}}
          id='kt_sign_in_submit'
          className='btn-new btn-hov d-inline-block sticky-bottom btn-lg text-white  w-100 mb-5 bg-one'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className=' indicator-label'>Giriş Yap</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Lütfen Bekleyin...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='d-grid gap-2'>
          {/* ${process.env.REACT_APP_ANASAYFA} */}
          <Link
            style={{borderRadius: ' 0px 15px 0px 15px',  color: '#fff'}}
            to={`/`}
            className='  btn-new btn-hov d-inline-block sticky-bottom  text-white fs-6 mb-0 bg-one'
          >
            Ön Panel
          </Link>
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
