import React, {useEffect, useState} from 'react'
// import Card from './Card'
import '../../../styles/payButton.css'
import {toAbsoluteUrl} from './../../../../../../_metronic/helpers/AssetHelpers'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../auth'
import PaymentForm from './Card'
import { FormattedMessage, useIntl } from 'react-intl'
//@ts-ignore
export default class Step2 extends React.Component<Props> {
  constructor(props: any) {
    super(props)
  }
  accountDetails = [
    {
      id: 'Bereket',
      name: 'Bereket Eğ. Sos. Yr. Der (Ziraat Katılım)',
      banklogo: 'ziraat.svg',
      TL_IBAN: 'TR080020900001283728000001',
      EURO_IBAN: 'TR240020900001283728000004',
      DOLAR_IBAN: 'TR510020900001283728000003',
    },
    {
      id: 'Vefa',
      name: 'Vefa İlim Hizmet ve Dayanışma Derneği',
      banklogo: 'albaraka.svg',
      TL_IBAN: 'TR38 0020 3000 0947 6164 0000 01',
      EURO_IBAN: '',
      DOLAR_IBAN: '',
    },
  ]
  toggle_degis = () => {
    this.props.setStep2Vals({...this.props.Step2Vals, toggle: !this.props.Step2Vals.toggle})
  }
  handleInputFocus = (e: any) => {
    this.props.setStep2Vals({...this.props.Step2Vals, focus: e.target.name})
  }
  handleInputChange = (e: any) => {
    const {name, value} = e.target
    var valx = value
    var newName = name
    if (name === 'month' || name === 'year') {
      // const oldexpiry=
      const [month, year] = this.props.Step2Vals.expiry.split('/')
      // Ay ve yıl değerlerini kontrol edin ve birleştirin

      let expiry = ''
      let monthnew = month ?? '07'
      let yearnew = year ?? '27'
      if (name === 'month') {
        monthnew = value
      } else if (name === 'year') {
        if(new Date().getFullYear().toString().slice(-2) == value && monthnew < new Date().getMonth() + 1){
          monthnew = '';
        }
        yearnew = value
      }
      expiry = `${monthnew}/${yearnew}`
      valx = expiry
      newName = 'expiry'
    } else if (name === 'number') {
      valx = valx.substring(0, 16)
    } else if (name === 'cvc') {
      valx = valx.substring(0, 4)
    }
    this.props.setStep2Vals({...this.props.Step2Vals, [newName]: valx})
  }

  render() {
    const currentMonth = new Date().getMonth() + 1
    const selectAccount = this.accountDetails.find(
      (accountDetail) => accountDetail.id == process.env.REACT_APP_CONTROL
    )
    return (
      <div className='container'>
        <div className='row justify-content-start mb-20 mt-n2 pb-5'>
          <div className='col-5   '></div>
          <div className='col-2   '>
            {this.props.currentUser && Number(this.props.currentUser.authority) === 1 ? (
              <label className='switch-pay '>
                <input
                  onChange={() => this.toggle_degis()}
                  className='pay-input '
                  type='checkbox'
                />
                <span className='slider'></span>
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
        {this.props.Step2Vals.toggle === false ? (
          <>
            <div className='row'>
              <div className='col-12'>
                <div className='row mb-5 w-100'>
                  <div id='PaymentForm'>
                    <div className='row align-items-center justify-content-center'>
                      <div className='col-sm-12 mb-4 Êcol-md-6'>
                        <PaymentForm
                          cvc={this.props.Step2Vals.cvc}
                          expiry={this.props.Step2Vals.expiry}
                          focused={this.props.Step2Vals.focus}
                          name={this.props.Step2Vals.name}
                          number={this.props.Step2Vals.number}
                          locale={{valid: 'Tarih'}}
                          placeholders={{name: 'İsim Soyisim'}}
                        />
                        {/* <Card
                          cvc={this.props.Step2Vals.cvc}
                          expiry={this.props.Step2Vals.expiry}
                          focused={this.props.Step2Vals.focus}
                          name={this.props.Step2Vals.name}
                          number={this.props.Step2Vals.number}
                          locale={{valid: 'Tarih'}}
                          placeholders={{name: 'İsim Soyisim'}}
                        /> */}
                      </div>
                      <div className='col-sm-12 col-md-4 ms-10'>
                        <form>
                          <label>{this.props.intl.formatMessage({id: 'MAIN.CARD.LABEL_CARDNAME'})}</label>
                          <input
                            className='form-control mb-5'
                            type='text'
                            name='name'
                            pattern='[A-Za-z]{3}'
                            value={this.props.Step2Vals.name}
                            placeholder='Ad Soyad'
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                          <label>{this.props.intl.formatMessage({id: 'MAIN.CARD.LABEL_CARDNUMBER'})}</label>
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            className='form-control mb-5'
                            type='tel'
                            name='number'
                            value={this.props.Step2Vals.number}
                            required
                            placeholder='Kart Numarası'
                            onChange={this.handleInputChange}
                            onPaste={(event) => {
                              event.preventDefault()
                              const pastedText = event.clipboardData.getData('text/plain')
                              // this.handleInputChange(event)
                              // const onlyNumbers = pastedText.replace(/[^\d]/g, '');
                              const onlyNumbers = pastedText.replace(/\D/g, '')
                              // const formattedText = pastedText.replace(/\s/g, '') // Boşlukları kaldır
                              this.handleInputChange({target: {name: 'number', value: onlyNumbers}})
                              // input alanına yapıştırılan değeri ekle
                            }}
                            onFocus={this.handleInputFocus}
                          />
                          {/*//? KART TARİH ALANI START  */}

                          <div className='row'>
                            <div className='col-md-4 mb-3'>
                              <label>{this.props.intl.formatMessage({id: 'MAIN.CARD.LABEL_CARDMONTH'})}</label>
                              <select
                                className='form-control'
                                value={this.props.Step2Vals.expiry.split('/')[0] ?? '07'}
                                onChange={(e) =>
                                  this.handleInputChange({
                                    target: {name: 'month', value: e.target.value},
                                  })
                                }
                              >
                                <option value=''>{this.props.intl.formatMessage({id: 'MAIN.CARD.LABEL_CARDMONTH'})}</option>
                                {
                                  //@ts-ignore
                                  [...Array(12)?.keys()].map((month) => (
                                    <option
                                    disabled ={ this.props.Step2Vals.expiry.split('/')[1] == new Date().getFullYear().toString().slice(-2) && month < currentMonth ? true : false}
                                      key={month + 1}
                                      value={`${month < 9 ? '0' : ''}${month + 1}`}
                                    >
                                      {`${month < 9 ? '0' : ''}${month + 1}`}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                            <div className='col-md-4 mb-3'>
                              <label>{this.props.intl.formatMessage({id: 'MAIN.CARD.LABEL_CARDYEAR'})}</label>
                              <select
                                className='form-control'
                                value={this.props.Step2Vals.expiry.split('/')[1] ?? '27'}
                                onChange={(e) =>
                                  this.handleInputChange({
                                    target: {name: 'year', value: e.target.value},
                                  })
                                }
                              >
                                <option value=''>{this.props.intl.formatMessage({id: 'MAIN.CARD.LABEL_CARDYEAR'})}</option>
                                {
                                  //@ts-ignore
                                  [...Array(18).keys()].map((year) => (
                                    <option
                                      key={year + new Date().getFullYear()}
                                      value={year + (new Date().getFullYear() % 100)}
                                    >
                                      {year + new Date().getFullYear()}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                            <div className='col-md-4 mb-3'>
                              <label>{this.props.intl.formatMessage({id: 'MAIN.CARD.LABEL_CARDCVC'})}</label>
                              <input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                className='form-control'
                                type='text'
                                name='cvc'
                                value={this.props.Step2Vals.cvc}
                                required
                                placeholder='CVC'
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                              />
                            </div>
                          </div>

                          {/* <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            className='form-control mb-5'
                            type='text'
                            name='expiry'
                            value={this.props.Step2Vals.expiry}
                            required
                            placeholder='Son Kullanma Tarihi'
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                            onPaste={(event) => {
                              event.preventDefault()
                              const pastedText = event.clipboardData.getData('text/plain')
                              // this.handleInputChange(event)
                              // const onlyNumbers = pastedText.replace(/[^\d]/g, '');
                              const onlyNumbers = pastedText.replace(/\D/g, '')
                              // const formattedText = pastedText.replace(/\s/g, '') // Boşlukları kaldır
                              this.handleInputChange({target: {name: 'expiry', value: onlyNumbers}})
                              // input alanına yapıştırılan değeri ekle
                            }}
                          /> */}
                          {/*//? KART TARİH ALANI END  */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <img
              className='w-25 h-15 mb-10'
              src={toAbsoluteUrl(`/media/payment/${selectAccount && selectAccount?.banklogo}`)}
            />
            <dl className='row w-100 '>
              <dt className='col-sm-3  h4'>
                <FormattedMessage id='USER.STEP2.ACCOUNT_NAME' />:{' '}
              </dt>
              <dd className='col-sm-9   lead'>{selectAccount && selectAccount.name}</dd>

              {selectAccount && selectAccount.TL_IBAN && (
                <>
                  <dt className='col-sm-3 h4'>
                    <FormattedMessage id='USER.STEP2.TL_IBAN' />:{' '}
                  </dt>
                  <dd className='col-sm-9  lead'>
                    <p>
                      {selectAccount.TL_IBAN}
                      <img
                        onClick={() => {
                          navigator.clipboard.writeText(selectAccount.TL_IBAN)
                          Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                          }).fire({
                            icon: 'success',
                            title: 'Başarılı bir şekilde kopyalandı',
                          })
                        }}
                        className='ms-3 me-3 mb-1'
                        src={toAbsoluteUrl('media/payment/copy.png')}
                      />
                    </p>
                  </dd>
                </>
              )}
              {selectAccount && selectAccount.EURO_IBAN && (
                <>
                  <dt className='col-sm-3 h4'>
                    <FormattedMessage id='USER.STEP2.EURO_IBAN' />:{' '}
                  </dt>
                  <dd className='col-sm-9  lead'>
                    <p>
                      {selectAccount.EURO_IBAN} (EURO)
                      <img
                        className='ms-3 me-3 mb-1'
                        src={toAbsoluteUrl('media/payment/copy.png')}
                        onClick={() => {
                          navigator.clipboard.writeText(selectAccount.EURO_IBAN)
                          Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                          }).fire({
                            icon: 'success',
                            title: 'Başarılı bir şekilde kopyalandı',
                          })
                        }}
                      />
                    </p>
                  </dd>
                </>
              )}
              {selectAccount && selectAccount.DOLAR_IBAN && (
                <>
                  <dt className='col-sm-3 h4'>
                    <FormattedMessage id='USER.STEP2.DOLAR_IBAN' />:{' '}
                  </dt>
                  <dd className='col-sm-9  lead'>
                    <p>
                      {selectAccount.DOLAR_IBAN} (DOLAR)
                      <img
                        className='ms-3 me-3 mb-1'
                        src={toAbsoluteUrl('media/payment/copy.png')}
                        onClick={() => {
                          navigator.clipboard.writeText(selectAccount.DOLAR_IBAN)
                          Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                          }).fire({
                            icon: 'success',
                            title: 'Başarılı bir şekilde kopyalandı',
                          })
                        }}
                      />
                    </p>
                  </dd>
                </>
              )}

              <dt className='col-sm-3 pb-sm-5 h4'>
                <FormattedMessage id='USER.STEP2.DEFINITION' />
              </dt>
              <dd className='col-sm-12 col-md-9 pb-sm-5  lead'>
                <b>
                  <FormattedMessage id='USER.STEP2.EXPLANATION' />
                </b>
              </dd>
            </dl>
          </>
        )}
      </div>
    )
  }
}
