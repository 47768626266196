import React, {FC, useEffect, useState} from 'react'
import {getPhoneCodesDB} from '../accounts/components/settings/cards/_requests'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import Select, {StylesConfig, defaultTheme} from 'react-select'
import {Dropdown} from 'react-bootstrap'
import {PatternFormat} from 'react-number-format'
import clsx from 'clsx'
import {phoneMasks} from './PhoneMasks'
const {colors} = defaultTheme

const selectStyles: StylesConfig<any, false> = {
  //@ts-ignore
  control: (provided) => ({
    ...provided,
    minWidth: 200,
    margin: 8,
  }),
  menu: () => ({boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'}),
}

const PhoneInputLocal2 = (props: any) => {
  const [phoneCodeList, setPhoneCodeList] = useState<any>()
  const [isOpen, setIsOpen] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [phoneCode, setPhoneCode] = useState('')
  const [phoneFormat, setPhoneFormat] = useState('(###)###-####')
  const [phoneMask, setPhoneMask] = useState('_')
  const [value, setValue] = useState<any | null>({
    id: 218,
    iso: 'TR',
    name: 'TURKEY',
    nicename: 'Turkey',
    iso3: 'TUR',
    numcode: '792',
    phonecode: 90,
    value: 90,
    label: 'TR +90',
  })

  const getPhoneCodes = async () => {
    await getPhoneCodesDB().then((degerli) => {
      setPhoneCodeList(degerli)
    })
  }
  useEffect(() => {
    getPhoneCodes()
  }, [])

  useEffect(() => {
    if (props.value && props.value != '') {
      setPhone(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (props.code && props.code != '' && phoneCodeList) {
      //setPhone(props.code);
      let phoneCodes = phoneCodeList.find((x: any) => x.phonecode === Number(props.code))
      if (phoneCodes.iso in phoneMasks) {
        setPhoneMask('_')
        setPhoneFormat(String(phoneMasks[phoneCodes.iso]))
      } else {
        setPhoneFormat('###################')
        setPhoneMask('')
      }
      setValue(phoneCodes)
    }
  }, [props.code, phoneCodeList])

  function convertPhoneNumberMask(input: string) {
    // İstenmeyen karakterleri temizle
    const cleaned = ('' + input).replace(/\D/g, '')

    // Uygun formata dönüştür
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{1})(\d{3})$/)
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`
    }
    return null
  }

  // function convertPhoneNumber(input: string) {
  //   // İstenmeyen karakterleri temizle
  //   const cleaned = ('' + input).replace(/\D/g, '');

  //   // Uygun formata dönüştür
  //   const match = cleaned.match(/^(\d{3})(\d{4})(\d{3})$/);
  //   if (match) {
  //     return `${match[1]}${match[2]}${match[3]}`;
  //   }
  //   return null;
  // }

  function convertPhoneNumber(str: string) {
    var regex = /\d+/g // Sayıları eşleştirecek düzenli ifade
    var sayilar = str.match(regex) // Düzenli ifadeye uyan tüm sayıları al
    var sadeceSayilar = sayilar ? sayilar.join('') : '' // Diziyi birleştirerek tek bir dize haline getir
    return sadeceSayilar
  }

  // Fonksiyonu kullanarak bir örnek:

  useEffect(() => {
    setIsOpen(false)
  }, [value])
  useEffect(() => {
    if (value?.value != '' && phone != '') {
      props.updateBasketCard(
        {target: {name: 'phone', value: value?.value + convertPhoneNumber(phone)}},
        props.index
      )
      props.updateBasketCard(
        {target: {name: 'phoneValue', value: convertPhoneNumber(phone)}},
        props.index
      )
    }
  }, [value, phone])

  const changePhoneCode = (newValue: any) => {
    if (newValue !== value) {
      setIsOpen(false)
    }
    props.updateBasketCard({target: {name: 'phoneCode', value: newValue?.phonecode}}, props.index)
    if (newValue.iso in phoneMasks) {
      setPhoneMask('_')
      setPhoneFormat(String(phoneMasks[newValue.iso]))
    } else {
      setPhoneFormat('###################')
      setPhoneMask('')
    }
    setValue(newValue)
  }

  return (
    <>
      <div className='row'>
        <div className='col-5 col-md-4 px-1'>
          <div className='mb-10'>
            {/* <select className="selectpicker form-select form-select-solid form-control-lg form-select-lg" aria-label="Select example"  onChange={props.onChange}>
              {
                phoneCodeList && phoneCodeList.map((phoneCode:any,key:number)=> (
                  <option value={phoneCode.phonecode}>{phoneCode.iso} +{phoneCode.phonecode}</option>
                ))
              }
            </select> */}
            {/* <Select
              options={options}
            /> */}

            <Dropdown show={isOpen} className='w-100'>
              <Dropdown.Toggle
                variant='light'
                id='dropdown-basic'
                size={props.size}
                className={clsx(
                  'w-100 ',
                  {'border border-danger': props.error && props.phone === ''},
                  {
                    'border border-success': !props.error && props.phone !== '',
                  },
                  {
                    'p-2': props.size === 'sm',
                  }
                )}
                onClick={() => setIsOpen((prev) => !prev)}
              >
                +{value?.value}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Select
                  autoFocus
                  // onFocus={() => setIsOpen((prev) => !prev)}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={(newValue) => {
                    changePhoneCode(newValue)
                  }}
                  options={phoneCodeList}
                  placeholder='Search...'
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={value}
                  onBlur={() => setIsOpen((prev) => !prev)}
                  formatOptionLabel={(country) => (
                    <div className='country-option'>
                      <img
                        src={toAbsoluteUrl('/media/flags/' + country.flag)}
                        width={20}
                        height={20}
                        alt='yoook'
                        className='mb-1'
                      />
                      <span className='ms-2 mt-5'>{country.label}</span>
                    </div>
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className={clsx('ps-1 col-7 col-md-8')}>
          <div className='mb-10'>
            <PatternFormat
              className={clsx(
                'form-control form-control-sm form-control-solid',
                {
                  'is-invalid border border-danger': props.error && props.phone === '',
                },
                {
                  'is-valid border border-success': !props.error && props.phone !== '',
                },
                {
                  'form-control-sm': props.size === 'sm',
                },
                {
                  'form-control-lg': props.size === 'lg',
                }
              )}
              value={phone}
              onChange={(e: any) => setPhone(e.target.value)}
              onBlur={props.onBlur}
              name={props.name}
              format={phoneFormat}
              allowEmptyFormatting
              mask={phoneMask}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {PhoneInputLocal2}
