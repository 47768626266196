import { ApexOptions } from "apexcharts"
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import ReactApexChart from "react-apexcharts";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { useEffect, useState } from "react";
import { getChartValues, getCategory } from "../../../../_metronic/partials/core/_requests";
import clsx from "clsx";
import { validate } from "webpack";

const ChartView = (dataChar: any) => {
  const d = new Date();
  let diger = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

  const [charCurrentTime, setCharCurrentTime] = useState<any>({ selection: 'one_month' })
  const [category, setCategory] = useState<any>([]);
  const [month, setMonth] = useState<any>([]);
  const [chartValuesChange, setChartValuesChange] = useState<any>(0);
  const getChartVal = async () => {
    const deger = await getChartValues(chartValuesChange);

    var finalArray = deger.map(function (obj: any) {
      return [obj.dates, obj.toplam];
    });
    setMonth(finalArray);

  }
  useEffect(() => {
    getChartVal()
  }, [chartValuesChange])
  const getCategorySelect = async () => {
    const deger = await getCategory();
    setCategory(deger);

  }

  useEffect(() => {
    getChartVal();
    getCategorySelect();


  }, [])
  const theme = useThemeMode()

  const chartData: ApexOptions = {

    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },

    xaxis: {
      type: 'datetime',
      //min: new Date().setFullYear( new Date().getFullYear() - 1),
      tickAmount: 12,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      },
      y: {
        formatter: function (value) {
          return value.toFixed(2); // Virgülden sonra 2 rakam gösterme
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },




  }
  const chartSeries: any[] = [{
data: month.map(([date, value]: [any, any]) => ({ x: date, y: value.toFixed(2) }))
  }]
  function updateData(timeline: any) {
    setCharCurrentTime({
      selection: timeline
    })

    switch (timeline) {
      case 'one_day':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setHours(new Date().getDay() - 25),
          new Date().setHours(new Date().getDay())
        )
        break
      case 'one_month':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setMonth(new Date().getMonth() - 1),
          new Date().setMonth(new Date().getMonth())
        )
        break
      case 'six_months':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setMonth(new Date().getMonth() - 6),
          new Date().setMonth(new Date().getMonth())
        )
        break
      case 'one_year':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setFullYear(new Date().getFullYear() - 1),
          new Date().setFullYear(new Date().getFullYear())
        )
        break
      // case 'ytd':
      //   ApexCharts.exec(
      //     'area-datetime',
      //     'zoomX',
      //     new Date('01 Jan 2013').getTime(),
      //     new Date('27 Feb 2013').getTime()
      //   )
      //   break
      case 'all':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setFullYear(new Date().getFullYear() - 150),
          new Date().setFullYear(new Date().getFullYear())
        )
        break
      default:
    }
  }

  return (
    <>

      <div className="toolbar d-flex justify-content-between">
        <div className="">
          <button id="one_day"

            onClick={() => updateData('one_day')}
            className={clsx(' btn btn-sm btn-success me-1', charCurrentTime.selection === 'one_day' ? 'active' : '')}>
            1G
          </button>
          <button id="one_month"

            onClick={() => updateData('one_month')}
            className={clsx(' btn btn-sm btn-success', charCurrentTime.selection === 'one_month' ? 'active' : '')}>
            1A
          </button>
          &nbsp;
          <button id="six_months"

            onClick={() => updateData('six_months')} className={clsx('btn btn-sm btn-success', charCurrentTime.selection === 'six_months' ? 'active' : '')}>
            6A
          </button>
          &nbsp;
          <button id="one_year"


            onClick={() => updateData('one_year')} className={clsx('btn btn-sm btn-success', charCurrentTime.selection === 'one_year' ? 'active' : '')}>
            1Y
          </button>
          &nbsp;


          <button id="all"

            onClick={() => updateData('all')} className={clsx('btn btn-sm btn-success', charCurrentTime.selection === 'all' ? 'active' : '')}>
            HEPSİ
          </button>
        </div>
        <div className="col-6">
          <select className="form-select"
            value={chartValuesChange}
            onChange={(e: any) => setChartValuesChange(e.target.value)}
            aria-label="Select example">
            <option>Lütfen Kategori Seçiniz</option>
            <option value="0">Hepsi</option>
            {category?.map((val: any, i: any) => {
              return (
                <option key={i} value={val.id}>{val.name}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div id="chart-timeline col-xl-4 ">
        <ReactApexChart options={chartData} type="area" series={chartSeries} />
      </div>

    </>) 
}
export default ChartView;
