import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DisableSidebar } from '../../_metronic/layout/core'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const DonatePage = lazy(() => import('../modules/guest/MainPage'))
  const DonationsTrackingPage = lazy(() => import('../modules/apps/donation-customer-management/DonationsTrackingPage'))
  const CustomerTrackingPage = lazy(() => import('../modules/apps/donation-customer-tracking/CustomerTrackingPage'))
  const BankTrackingPage = lazy(() => import('../modules/apps/bank-transactions-management/BankTrackingPage'))
  const PaysPage = lazy(() => import('../modules/apps/donation-pay-managemenet/PaysPage'))
  const DonationsPage = lazy(() => import('../modules/apps/donation-management/DonationsPage'))
  const DonationsMediaPage = lazy(() => import('../modules/apps/donation-media/DonationsMediaPage'))
  const DonationsUserPage = lazy(() => import('../modules/apps/user-donation-management/DonationsPage'))
  const CategoriesPage = lazy(() => import('../modules/apps/donation-categories/CategoriesPage'))
  const CallCenterPage = lazy(() => import('../modules/apps/call-center-management/CallCenterPage'))
  const AuthorityPage = lazy(() => import('../modules/apps/authority-management/AuthorityPage'))
  const VictimPage = lazy(() => import('../modules/apps/victim-management/VictimPage'))
  const StudentsPage = lazy(() => import('../modules/apps/donation-students/StudentsPage'))
  const AutoPayPage = lazy(() => import('../modules/apps/donation-autopay-managemenet/AutoPayPage'))
  const SettingPage = lazy(() => import('../modules/apps/settings-management/SettingPage'))
  const DesignPage = lazy(() => import('../modules/apps/design-management/DesignPage'))
  const UserStudentPage = lazy(() => import('../modules/apps/user-student-list/StudentsPage'))
  const UserOtoOdePage = lazy(() => import('../modules/apps/user-otoode-list/OtoodePage'))
  const NotesUserPage = lazy(() => import('../modules/apps/user-notes-list/NotesPage'))
  const NewRegistration = lazy(() => import('../modules/auth/components/NewRegistration'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/admin/dashboard' />} />
        {/* Pages */}
        <Route path='' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='user_account' element={<AccountPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/newAccount/*'
          element={
            <SuspensedView>
              <NewRegistration />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation-tracking/*'
          element={
            <SuspensedView>
              <DonationsTrackingPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation-management/*'
          element={
            <SuspensedView>
              <DonationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation-media/*'
          element={
            <SuspensedView>
              <DonationsMediaPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/bank_transactions/*'
          element={
            <SuspensedView>
              <BankTrackingPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/customer-management/*'
          element={
            <SuspensedView>
              <CustomerTrackingPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation-pay-management/*'
          element={
            <SuspensedView>
              <PaysPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation-autopay-management/*'
          element={
            <SuspensedView>
              <AutoPayPage />
            </SuspensedView>
          }
        />
        <Route

          path='apps/call-center/*'
          element={
            <SuspensedView>
              <CallCenterPage />
            </SuspensedView>
          }
        />
        <Route

          path='apps/authority/*'
          element={
            <SuspensedView>
              <AuthorityPage />
            </SuspensedView>
          }
        />
        <Route
          path='donation-user-list/*'
          element={
            <SuspensedView>
              <DonationsUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='donation-user-notes/*'
          element={
            <SuspensedView>
              <NotesUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='donation-student-list/*'
          element={
            <SuspensedView>
              <UserStudentPage />
            </SuspensedView>
          }
        />
        <Route
          path='donation-user-otoode/*'
          element={
            <SuspensedView>
              <UserOtoOdePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/victim-management/*'
          element={
            <SuspensedView>
              <VictimPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/*'
          element={
            <SuspensedView>
              <StudentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation-categories/*'
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/setting-management/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/design-management/*'
          element={
            <SuspensedView>
              <DesignPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='admin/*' element={<Navigate to='/error/404' />} />
        <Route path='user/*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export { PrivateRoutes }
