import {useEffect, useState} from 'react'
import {Dropdown, Offcanvas} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {Navigate, Link, NavLink, useHref} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../auth'

import '../../styles/Header.css'
import {useIntl} from 'react-intl'
import {HeaderItems} from '../../../apps/settings-management/callcenter-list/core/_requests'
import React from 'react'
//import { toAbsoluteUrl } from './../../../../../_metronic/helpers/AssetHelpers';

function Header() {
  const {currentUser, logout, genericValues} = useAuth()
  const [show, setShow] = useState(false)
  const [langData, setLangData] = React.useState<any>(genericValues?.langJson)

  const [headerList, setHeaderList] = useState<any>([])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const intl = useIntl()
  useEffect(() => {
    //getHeaderList()
    HeaderItems().then((res) => {
      setHeaderList(res)
    })
  }, [])
  useEffect(() => {
    setLangData(genericValues?.langJson)
  }, [genericValues])
  return (
    <>
      <Navbar
        style={{background: 'rgba(255,255,255,0.3)', backdropFilter: 'blur(7px)'}}
        className='sticky-top'
        bg='soft'
        expand='lg'
      >
        <Container>
          <Navbar.Brand href='/'>
            {/* <img
              style={{width: '200px', height: '50px'}}
              src={
                headerList?.images
                  ? `${
                      process.env.REACT_APP_MEDIA_API_URL +
                      'headerImages/' +
                      headerList?.images[0]?.photo_url
                    }`
                  : `${toAbsoluteUrl('/media/logos/bereket-logo.png')}`
              }
              // src={toAbsoluteUrl('/media/logos/bereket-logo.png')}
            /> */}
            <img
              style={{width: '160px', height: '60px', objectFit: 'contain'}}
              alt='Logo'
              src={`${
                headerList?.images
                  ? process.env.REACT_APP_MEDIA_API_URL +
                    'headerImages/' +
                    headerList?.images[0]?.photo_url
                  : process.env.REACT_APP_MEDIA_API_URL + 'static/header-logo.png'
              }`}
              onError={({currentTarget}) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = `${process.env.REACT_APP_MEDIA_API_URL}static/header-logo.png`
              }}
              // src={toAbsoluteUrl('/media/logos/bereket-logo.png')}
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Offcanvas
            onHide={handleClose}
            className=' text-white '
            style={{backgroundColor: '#0e7154', width: '250px'}}
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='mt-5 pe-0'>
              <Nav
                className='justify-content-between  flex-grow-1 pe-3 h5 colorsforheader'
                
              >
                <div className='align-items-center  d-flex  flex-column-sm  ' >
                  {headerList?.header?.map((item: any, index: any) => (
                    <div
                      className='align-items-start justify-content-start float-start d-flex'
                      key={index}
                    >
                      {item?.subItems?.length < 1 ? (
                        <Nav.Link href={item.link}>
                          <span>
                            {langData
                              ? langData['DB.HEADER_MENU.VALUE_' + item.id] ?? item.name
                              : ''}
                          </span>
                        </Nav.Link>
                      ) : (
                        <NavDropdown
                          title={
                            <span>
                              {langData
                                ? langData['DB.HEADER_MENU.VALUE_' + item.id] ?? item.name
                                : ''}
                            </span>
                          }
                          id={`offcanvasNavbarDropdown-${index}`}
                        >
                          {item.subItems.map((subItem: any, subIndex: any) => (
                            <NavDropdown.Item
                              key={subIndex}
                              className='h5 fw-bold'
                              href={subItem.link}
                            >
                              {langData
                                ? langData['DB.HEADERSUB_MENU.VALUE_' + subItem.id] ?? item.name
                                : ''}
                            </NavDropdown.Item>
                          ))}
                          <NavDropdown.Divider />
                        </NavDropdown>
                      )}
                    </div>
                  ))}
                </div>
                <div className='justify-content-end'>
                  <Dropdown>
                    <Dropdown.Toggle style={{color: '#cab47b'}} variant='' id='dropdown-basic'>
                      <i
                        style={{color: '#cab47b'}}
                        className='m-1 fa-sharp fa-solid fa-user-tie fs-2x'
                      ></i>
                      {currentUser ? (
                        `${currentUser.adi + ' ' + currentUser.soyadi}`
                      ) : (
                        <span className='fw-bolder'>
                          {' '}
                          {intl.formatMessage({id: 'HEADER.LOGIN'})}
                        </span>
                      )}
                    </Dropdown.Toggle>

                    {currentUser ? (
                      <>
                        <Dropdown.Menu className='dropdown-menu'>
                          <Dropdown.Item
                            className='p-3 text-center'
                            href={`${process.env.REACT_APP_ANASAYFA}auth`}
                          >
                            <b>{intl.formatMessage({id: 'HEADER.MYACCOUNT'})}</b>
                          </Dropdown.Item>
                          <div className='d-grid gap-2'>
                            <button
                              className='btn btn-danger d-block btn-sm'
                              onClick={() => logout()}
                            >
                              {intl.formatMessage({id: 'HEADER.LOGOUT'})}
                            </button>
                          </div>
                        </Dropdown.Menu>
                      </>
                    ) : (
                      <>
                        <Dropdown.Menu className='dropdown-menu'>
                          {currentUser ? (
                            <>
                              <Dropdown.Item href={`${process.env.REACT_APP_ANASAYFA}auth`}>
                                {intl.formatMessage({id: 'HEADER.MYACCOUNT'})}
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              <Dropdown.Item href={`${process.env.REACT_APP_ANASAYFA}auth`}>
                                {' '}
                                {intl.formatMessage({id: 'HEADER.LOGIN'})}
                              </Dropdown.Item>
                              <Dropdown.Item href={`${process.env.REACT_APP_ANASAYFA}registration`}>
                                {intl.formatMessage({id: 'HEADER.REGISTER'})}
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </>
                    )}
                  </Dropdown>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <div className='separator border-3 my-2'></div>
    </>
  )
}

export default Header
